import { visitType } from "./visit.type";
const initialState = {
  // loading: true,
  // details: [],
  loading: false,
  details: [],
  error: "",
};

export const visitReducer = (state = initialState, action) => {
  switch (action.type) {
    case visitType.VISIT_REQUEST:
      return { ...initialState, loading: true };
    case visitType.VISIT_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        details: action.details,
      };
    case visitType.VISIT_REQUEST_FAILURE:
      return { ...state, error: action.error, loading: false };
    case visitType.VISIT_REQUEST_LOAD_MORE:
      return { ...state, loadingMore: true };
    case visitType.VISIT_REQUEST_LOAD_MORE_SUCCESS:
      return {
        ...state,
        loadingMore: false,
        details: [...state.details, ...action.detailsLoad],
      };
    case visitType.VISIT_REQUEST_LOAD_MORE_FAILURE:
      return { ...initialState, error: action.error };
    default:
      return state;
  }
};
