import { connectCMType } from "../types";
export const connectCMActions = {
  connectCMRequest,
  connectCMRequestSuccess,
  connectCMRequestFailure,

  //send message
  connectCMMsgRequest,
  connectCMMsgRequestSuccess,
  connectCMMsgRequestFailure,
  connectCMMsgRequestReset,
};
//get all msg

function connectCMRequest(request, token) {
  return {
    type: connectCMType.CONNECT_CM_REQUEST,
    request,
    token,
  };
}
function connectCMRequestSuccess(messages) {
  return {
    type: connectCMType.CONNECT_CM_REQUEST_SUCCESS,
    messages: messages,
  };
}
function connectCMRequestFailure(error) {
  return {
    type: connectCMType.CONNECT_CM_REQUEST_FAILURE,
    errorMsgs: error,
  };
}

//send msg

function connectCMMsgRequest(request, token) {
  return {
    type: connectCMType.CONNECT_CM_MSG_REQUEST,
    request,
    token,
  };
}
function connectCMMsgRequestSuccess(message) {
  return {
    type: connectCMType.CONNECT_CM_MSG_REQUEST_SUCCESS,
    message: message,
  };
}
function connectCMMsgRequestFailure(error) {
  return {
    type: connectCMType.CONNECT_CM_MSG_REQUEST_FAILURE,
    errorSent: error,
  };
}
function connectCMMsgRequestReset() {
  return {
    type: connectCMType.CONNECT_CM_MSG_REQUEST_RESET,
  };
}
