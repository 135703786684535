import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AppBar } from "../../../components";
import { getEachWordFirstChar } from "../../../util";
import { connectPCPActions } from "./actions";
import { MsgItem } from "./components";
import styles from "./ConnectPCP.module.scss";

import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { progressActions } from "../../../store/actions/progress.action";
import { strings } from "./strings";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const ConnectPCP = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.login.userData);
  const connectPCP = useSelector((state) => state.connectPCP);
  const [messages, setMessages] = useState([]);
  const [pcp, setPCP] = useState({ PCPID: "", PCPName: "" });
  const [newMessage, setMessage] = useState("");
  const [scroll, setScroll] = useState("auto");
  const messagesEndRef = useRef(null);
  const profileData = useSelector((state) => state.profile.data);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    if (profileData.PCPInfo.length > 0) {
      let pcpData = profileData.PCPInfo[0];

      if (pcpData.MyPreferencePCP !== undefined) {
        setPCP({
          PCPName: pcpData.MyPreferencePCP,
          PCPID: pcpData.MyPreferencePCPID,
        });
      } else {
        setPCP({
          PCPName: pcpData.PCPName,
          PCPID: pcpData.PCPID,
        });
      }
    }
  }, [profileData]);

  useEffect(() => {
    if (connectPCP.loading) {
      dispatch(progressActions.progressRequest("Loading..."));
    } else {
      dispatch(progressActions.progressRequestReset(""));
    }
    if (
      connectPCP.messages !== undefined &&
      connectPCP.messages.length > 0 &&
      connectPCP.messages[0].Result === undefined
    ) {
      setMessages([...connectPCP.messages]);
    } else {
      setMessages([]);
    }
  }, [connectPCP.messages, connectPCP.loading]);

  useEffect(() => {
    // setMessages([...msgsList]);
    if (userData.token !== undefined && pcp.PCPID !== "") {
      let request = {
        deviceid: userData.DeviceID,
        roleIdentityID: pcp.PCPID,
      };

      dispatch(connectPCPActions.connectPCPRequest(request, userData.token));
    }
  }, [pcp]);
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: scroll });
  };

  const sendMessage = () => {
    if (userData.token !== undefined) {
      // let request = {
      //   ChatMessage: newMessage,
      //   deviceid: userData.DeviceID,
      //   roleIdentityID: pcp.PCPID,
      // };
      let request = {
        chatMessage: newMessage,
        deviceid: userData.DeviceID,
        roleIdentityID: pcp.PCPID,
      };

      dispatch(connectPCPActions.connectPCPMsgRequest(request, userData.token));
    }
  };

  useEffect(() => {
    if (connectPCP.sentMessage === "Success") {
      let newMsg = [
        {
          id: "" + new Date().getTime(),
          text: newMessage,
          createdAt: new Date(),
          user: {
            id: "1",
            senderName:
              userData.FirstName +
              " " +
              userData.MiddleName +
              " " +
              userData.LastName,
          },
        },
      ];

      setMessages([...messages, ...newMsg]);
      setScroll("smooth");
      setMessage("");

      dispatch(connectPCPActions.connectPCPMsgRequestReset());
    }
  }, [connectPCP.sentMessage]);

  const handleClose = (event, reason) => {
    dispatch(connectPCPActions.connectPCPMsgRequestReset());
    if (reason === "clickaway") {
      return;
    }
  };
  return (
    <>
      <AppBar
        title={`Connect to ${pcp.PCPName !== "" ? pcp.PCPName : ""}`}
        onBack={() => navigate(-1)}
      />

      <div className={styles.container}>
        <div className={styles.messages_container}>
          <div className={styles.messages}>
            {messages !== undefined && messages.length === 0 ? (
              <div className={styles.no_msg}>{strings.NO_CONVO}</div>
            ) : (
              messages.map((item, index) => (
                <MsgItem key={item.id} item={item} />
              ))
            )}
            <div ref={messagesEndRef} />
          </div>
        </div>
        {/* <div className={styles.send_container}> */}
        <div className={styles.send_container}>
          <textarea
            value={newMessage}
            className={styles.msg_input}
            type={"text"}
            placeholder="Your message..."
            onChange={(e) => setMessage(e.target.value)}
            rows={1}
            cols={2}
          />
          <button
            className={styles.send_btn}
            onClick={sendMessage}
            disabled={newMessage.trim() === ""}
          >
            Send
          </button>
        </div>
        {/* </div> */}
        <Snackbar
          open={connectPCP.errorSent !== ""}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
            {connectPCP.errorSent}
          </Alert>
        </Snackbar>
        <Snackbar
          open={connectPCP.errorMsgs !== ""}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
            {connectPCP.errorMsgs}
          </Alert>
        </Snackbar>
      </div>
    </>
  );
};
