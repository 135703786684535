import {visitType} from './visit.type';
export const visitActions = {
  visitRequest,
  visitRequestSuccess,
  visitRequestFailure,

  visitRequestLoadMore,
  visitRequestLoadMoreSuccess,
  visitRequestLoadMoreFailure,
};
function visitRequest(request, token) {
  return {
    type: visitType.VISIT_REQUEST,
    request,
    token,
  };
}

function visitRequestSuccess(details) {
  return {
    type: visitType.VISIT_REQUEST_SUCCESS,
    details: details,
  };
}
function visitRequestFailure(error) {
  return {
    type: visitType.VISIT_REQUEST_FAILURE,
    error: error,
  };
}

function visitRequestLoadMore(request, token) {
  return {
    type: visitType.VISIT_REQUEST_LOAD_MORE,
    request,
    token,
  };
}
function visitRequestLoadMoreSuccess(detailsLoad) {
  return {
    type: visitType.VISIT_REQUEST_LOAD_MORE_SUCCESS,
    detailsLoad: detailsLoad,
  };
}
function visitRequestLoadMoreFailure(error) {
  return {
    type: visitType.VISIT_REQUEST_LOAD_MORE_FAILURE,
    error: error,
  };
}
