import { put, takeLatest, call } from "@redux-saga/core/effects";
import { Api } from "../../../api";
import { endpoint } from "../../../api/Url";
import { authErrorActions } from "../../../store/actions/authError.action";
import { errorActions } from "../../../store/actions/error.action";
import { cmConversationsActions } from "./cmConversations.action";
import { cmConversationsType } from "./cmConversations.type";

export function* watchCmConversations() {
  yield takeLatest(
    cmConversationsType.CM_CONVERSATIONS_REQUEST,
    getCmConversations
  );
}

function* getCmConversations(action) {
  try {
    //api call

    const details = yield call(Api, {
      endpoint: endpoint.cmConversations,
      method: "POST",
      body: action.request,
      jwt: "Bearer " + action.token,
    });

    const parsedDetails = JSON.parse(details);

    // if (
    //   parsedDetails[0].length > 0 &&
    //   parsedDetails[0].Result == "Device is changed"
    // ) {
    //   yield put(
    //     cmConversationsActions.cmConversationsRequestFailure(
    //       "Something went wrong!"
    //     )
    //   );
    // } else {
    //   yield put(
    //     cmConversationsActions.cmConversationsRequestSuccess(parsedDetails)
    //   );
    // }

    yield put(
      cmConversationsActions.cmConversationsRequestSuccess(parsedDetails)
    );
  } catch (error) {
    yield put(cmConversationsActions.cmConversationsRequestFailure(error));
  }
}
