import React, { useEffect, useState } from "react";
import styles from "./PCP.module.scss";
import { useNavigate } from "react-router-dom";
import { AppBar } from "../../../components";
import { strings } from "./strings";
import { BtnPrimary } from "../../../core";
import { BtnSecondary } from "../../../components2";
import { isEmpty } from "../../../util";
import { ValidationError } from "../../../components2";
import { default as ReactSelect } from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { pcpActions } from "./pcp.action";
import { progressActions } from "../../../store/actions";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { profileActions } from "../Profile/profile.action";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
export const PCP = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const pcps = useSelector((state) => state.pcps);
  const userData = useSelector((state) => state.login.userData);
  const profileData = useSelector((state) => state.profile.data);

  const [pcp, setPCP] = useState("");
  const [errorPCP, setErrorPCP] = useState(false);

  useEffect(() => {
    if (profileData.PCPInfo !== undefined && profileData.PCPInfo.length > 0) {
      let pcpInfo = profileData.PCPInfo[0];
      setPCP({
        PCP: pcpInfo.MyPreferencePCP,
        PCPID: pcpInfo.MyPreferencePCPID,
      });
    }
  }, [profileData]);
  const updatePCP = () => {
    if (!validate()) {
      if (userData.token !== undefined) {
        let request = {
          pcpid: pcp.PCPID,
          pcpname: pcp.PCP,
          deviceid: userData.DeviceID,
        };
        dispatch(pcpActions.pcpRequest(request, userData.token));
      }
    }
  };

  useEffect(() => {
    if (userData.token !== undefined) {
      let req = { deviceid: userData.DeviceID };
      dispatch(profileActions.profileRequest(req, userData.token));
    }
  }, []);

  const validate = () => {
    let cancel = false;
    if (isEmpty(pcp)) {
      setErrorPCP(true);
      cancel = true;
    } else {
      setErrorPCP(false);
    }
    return cancel;
  };

  const getPcp = (val) => {
    if (val.length > 2) {
      if (userData.token !== undefined) {
        let request = {
          searchStr: val,
        };

        dispatch(pcpActions.getAllPCPRequest(request, userData.token));
      }
    }
  };
  useEffect(() => {
    if (pcps.updateLoading) {
      dispatch(progressActions.progressRequest("Updating PCP..."));
    } else {
      dispatch(progressActions.progressRequestReset());
      // setTimeout(() => {
      //   dispatch(pcpActions.pcpRequestReset());
      // }, 1000);
    }
  }, [pcps.updateLoading, pcps.message]);
  const handleClose = (event, reason) => {
    dispatch(pcpActions.pcpRequestReset());
    if (reason === "clickaway") {
      return;
    }
  };
  return (
    <>
      <>
        <AppBar title={"Settings"} onBack={() => navigate(-1)} />
        <div className={styles.container}>
          <div className={styles.update_pcp}>
            <div className={styles.row}>
              <label className={styles.label}>{strings.PCP_NAME}</label>
              <ReactSelect
                onInputChange={getPcp}
                getOptionLabel={(option) => option.PCP}
                getOptionValue={(option) => option.PCPID}
                options={pcps.getAll}
                isLoading={pcps.getAllLoading}
                value={pcp}
                onChange={(val) => setPCP(val)}
              />

              <ValidationError hasError={errorPCP} errorMessage={"*Required"} />
            </div>
            <p className={styles.info}>
              You can update your Primary Care Physician(PCP) by entering or
              selecting it.
            </p>
            <div className={styles.button}>
              <BtnSecondary
                label={strings.CANCEL}
                onClick={() => navigate(-1)}
              />
              <BtnPrimary label={strings.UPDATE} onClick={updatePCP} />
            </div>
            {/* <Alert
              isSuccess={pcps.message === "Success"}
              successMessage={"Successfully updated!"}
              errorMessage="Failed to update!"
            /> */}
            <Snackbar
              open={pcps.message !== ""}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              onClose={handleClose}
            >
              <Alert
                onClose={handleClose}
                severity={"success"}
                sx={{ width: "100%" }}
              >
                {"Successfully updated!"}
              </Alert>
            </Snackbar>
            <Snackbar
              open={pcps.error !== ""}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              onClose={handleClose}
            >
              <Alert
                onClose={handleClose}
                severity={"error"}
                sx={{ width: "100%" }}
              >
                {"Failed to update!"}
              </Alert>
            </Snackbar>
          </div>
        </div>
      </>
    </>
  );
};
