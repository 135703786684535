import { stateType } from "../types";
export const stateActions = {
  stateRequest,
  stateRequestSuccess,
  stateRequestFailure,
};

function stateRequest(searchState, token) {
  return { type: stateType.STATE_REQUEST, searchState, token };
}
function stateRequestSuccess(states) {
  return { type: stateType.STATE_REQUEST_SUCCESS, states };
}
function stateRequestFailure(error) {
  return { type: stateType.STATE_REQUEST_FAILURE, error };
}
