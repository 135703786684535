import React from "react";
import styles from "./AppBar.module.scss";
import { BiLeftArrowAlt } from "react-icons/bi";
import IconButton from "@mui/material/IconButton";

export const AppBar = ({ onBack, title }) => {
  return (
    <div className={styles.container}>
      {/* <Link to={backTo}> */}
      <IconButton
        size="large"
        edge="start"
        aria-label="menu"
        className={styles.back_container}
        onClick={onBack}
      >
        <BiLeftArrowAlt className={styles.back} />
      </IconButton>
      {/* </Link> */}

      <div className={styles.title}>{title}</div>
    </div>
    // <Box sx={{ flexGrow: 1 }}>
    //   <AppBar position="static">
    //     <Toolbar>
    //       <IconButton
    //         size="large"
    //         edge="start"
    //         color="inherit"
    //         aria-label="menu"
    //         sx={{ mr: 2 }}
    //       >
    //         <BiLeftArrowAlt />
    //       </IconButton>
    //       <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
    //         News
    //       </Typography>
    //     </Toolbar>
    //   </AppBar>
    // </Box>
  );
};
