import { put, takeLatest, call } from "@redux-saga/core/effects";
import { contactRoleActions } from "../actions/contactRole.action";
import { contactRoleTypes } from "../types/contactRole.type";
import { endpoint } from "../../../api/Url";
import { Api } from "../../../api";
// import { loginActions } from "../../Login/actions/login.action";

export function* watchContactRole() {
  yield takeLatest(contactRoleTypes.CONTACT_ROLE_REQUEST, getContactRole);
}

function* getContactRole(action) {
  try {
    const contactRole = yield call(Api, {
      endpoint: endpoint.getContactRoles,
      method: "POST",
      jwt: "Bearer " + action.token,
      // body: action.request,
    });
    const parsedContactRole = JSON.parse(contactRole);

    yield put(contactRoleActions.contactRoleRequestSuccess(parsedContactRole));
  } catch (error) {
    yield put(contactRoleActions.contactRoleRequestFailure(error));

    // if (error.code === 401 || error.code === 403)
    //   yield put(loginActions.logoutRequest());
  }
}
