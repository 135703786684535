import {
  put,
  takeLatest,
  takeEvery,
  call,
  delay,
} from "@redux-saga/core/effects";
import { Api } from "../../../../api";
import { endpoint } from "../../../../api/Url";
import { connectPCPActions } from "../actions";
import { connectPCPType } from "../types";

import msgs from "../../Support/msgs.json";

export function* watchConnectPCP() {
  yield takeLatest(connectPCPType.CONNECT_PCP_REQUEST, getConnectPCP);
  yield takeEvery(connectPCPType.CONNECT_PCP_MSG_REQUEST, sendMsg);
}

function* getConnectPCP(action) {
  try {
    //api call
    // yield delay(1000);

    // api call
    const messages = yield call(Api, {
      endpoint: endpoint.getPCPChat,
      method: "POST",
      body: action.request,
      jwt: "Bearer " + action.token,
    });

    const parsedData = JSON.parse(messages);

    if (parsedData[0].Result == "Device is changed") {
      yield put(
        connectPCPActions.connectPCPRequestFailure("Something went wrong!")
      );
    } else {
      yield put(connectPCPActions.connectPCPRequestSuccess(parsedData));
    }
  } catch (error) {
    // if (error === 403 || error === 401) {
    //   yield put(
    //     authErrorActions.setAuthError(
    //       true,
    //       "Session Expired",
    //       "Please log in again."
    //     )
    //   );
    // } else {
    //   //  yield put(errorActions.setError(true, 'Failure!', error));
    yield put(
      connectPCPActions.connectPCPRequestFailure("Something went wrong!")
    );
    // }
  }
}

function* sendMsg(action) {
  try {
    //api call

    const message = yield call(Api, {
      endpoint: endpoint.sendPCPMessage,
      method: "POST",
      body: action.request,
      jwt: "Bearer " + action.token,
    });

    const parsedMessage = JSON.parse(message);

    if (parsedMessage[0].Result === "Success") {
      yield put(
        connectPCPActions.connectPCPMsgRequestSuccess(parsedMessage[0].Result)
      );
    } else {
      yield put(
        connectPCPActions.connectPCPMsgRequestFailure("Failed to sent!")
      );
    }
  } catch (error) {
    yield put(connectPCPActions.connectPCPMsgRequestFailure("Failed to sent!"));
  }
}
