import { supportType } from "../types";
export const supportActions = {
  supportRequest,
  supportRequestSuccess,
  supportRequestFailure,

  //send message
  supportMsgRequest,
  supportMsgRequestSuccess,
  supportMsgRequestFailure,
  supportMsgRequestReset,
};
//get all msg
function supportRequest(request, token) {
  return {
    type: supportType.SUPPORT_REQUEST,
    request,
    token,
  };
}
function supportRequestSuccess(messages) {
  return {
    type: supportType.SUPPORT_REQUEST_SUCCESS,
    messages: messages,
  };
}
function supportRequestFailure(error) {
  return {
    type: supportType.SUPPORT_REQUEST_FAILURE,
    errorMsgs: error,
  };
}

//send msg

function supportMsgRequest(request, token) {
  return {
    type: supportType.SUPPORT_MSG_REQUEST,
    request,
    token,
  };
}
function supportMsgRequestSuccess(message) {
  return {
    type: supportType.SUPPORT_MSG_REQUEST_SUCCESS,
    message: message,
  };
}
function supportMsgRequestFailure(error) {
  return {
    type: supportType.SUPPORT_MSG_REQUEST_FAILURE,
    errorSent: error,
  };
}
function supportMsgRequestReset() {
  return {
    type: supportType.SUPPORT_MSG_REQUEST_RESET,
  };
}
