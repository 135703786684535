import React from "react";
import styles from "./Settings.module.scss";
import { useNavigate } from "react-router-dom";
import { AppBar } from "../../../components";
import updatePcpImg from "../../../assets/images/update_doctor.png";
import { Row } from "react-bootstrap";
import { Widget } from "../../home/Home/components";

export const Settings = () => {
  const navigate = useNavigate();
  return (
    <>
      <AppBar title={"Settings"} onBack={() => navigate(-1)} />
      <div className={styles.container}>
      <Row className={styles.details_container}>
        <Widget
          onClick={() => navigate("/pcp")}
          label="Update PCP"
          ico={<img src={updatePcpImg} className={styles.ico} />}
        />
      </Row>
    </div>
    </>
  );
};
