import { put, takeLatest, takeEvery, call } from "@redux-saga/core/effects";
import { Api } from "../../../../../../api";
import { endpoint } from "../../../../../../api/Url";
import { connectCMActions } from "../actions";
import { connectCMType } from "../types";

export function* watchConnectCM() {
  yield takeLatest(connectCMType.CONNECT_CM_REQUEST, getConnectCM);
  yield takeEvery(connectCMType.CONNECT_CM_MSG_REQUEST, sendMsg);
}

function* getConnectCM(action) {
  try {
    //api call

    //api call
    const messages = yield call(Api, {
      endpoint: endpoint.getConnectCMChat,
      method: "POST",
      body: action.request,
      jwt: "Bearer " + action.token,
    });

    const parsedData = JSON.parse(messages);

    // if (parsedData[0].ResultparsedData[0].Result == "Device is changed") {
    //   yield put(
    //     connectCMActions.connectCMRequestFailure("Something went wrong!")
    //   );
    // } else {
    yield put(connectCMActions.connectCMRequestSuccess(parsedData));
    //  }
  } catch (error) {
    console.log(error);
    yield put(
      connectCMActions.connectCMRequestFailure("Something went wrong!")
    );
  }
}

function* sendMsg(action) {
  try {
    //api call

    const message = yield call(Api, {
      endpoint: endpoint.sendCMMessage,
      method: "POST",
      body: action.request,
      jwt: "Bearer " + action.token,
    });

    const parsedMessage = JSON.parse(message);
    if (parsedMessage[0].Result === "Success") {
      yield put(
        connectCMActions.connectCMMsgRequestSuccess(parsedMessage[0].Result)
      );
    } else {
      yield put(connectCMActions.connectCMMsgRequestFailure("Failed to sent!"));
    }
  } catch (error) {
    if (error === 403 || error === 401) {
      //   yield put(
      //     authErrorActions.setAuthError(
      //       true,
      //       "Session Expired",
      //       "Please log in again."
      //     )
      //   );
      // } else {
      //   yield put(errorActions.setError(true, "Failure!", error));
      yield put(connectCMActions.connectCMMsgRequestFailure("Failed to sent!"));
    }
  }
}
