export const pcpType = {
  PCP_UPDATE_REQUEST: 'PCP_UPDATE_REQUEST',
  PCP_UPDATE_REQUEST_SUCCESS: 'PCP_UPDATE_REQUEST_SUCCESS',
  PCP_UPDATE_REQUEST_FAILURE: 'PCP_UPDATE_REQUEST_FAILURE',
  PCP_UPDATE_REQUEST_RESET: 'PCP_UPDATE_REQUEST_RESET',

  GETALL_PCP_REQUEST: 'GETALL_PCP_REQUEST',
  GETALL_PCP_REQUEST_SUCCESS: 'GETALL_PCPE_REQUEST_SUCCESS',
  GETALL_PCP_REQUEST_FAILURE: 'GETALL_PCP_REQUEST_FAILURE',
};
