import { put, takeLatest, call, delay } from "@redux-saga/core/effects";
import { Api } from "../../../api";
import { endpoint, jwt } from "../../../api/Url";
import { visitActions } from "./visit.action";
import { visitType } from "./visit.type";
import { errorActions } from "../../../store/actions/error.action";
import { authErrorActions } from "../../../store/actions/authError.action";

export function* watchVisit() {
  yield takeLatest(visitType.VISIT_REQUEST, getVisitDetails);
  // yield takeLatest(visitType.VISIT_REQUEST_LOAD_MORE, getVisitDetailsLoadMore);
}

function* getVisitDetails(action) {
  try {
    //api call

    const details = yield call(Api, {
      endpoint: endpoint.visitDetails,
      method: "POST",
      body: action.request,
      jwt: "Bearer " + action.token,
    });

    const parsedDetails = JSON.parse(details);
    console.log(details);
    console.log("parsedDetails", parsedDetails);
    if (
      parsedDetails.length > 0 &&
      parsedDetails[0].Result === "Device is changed"
    ) {
      yield put(visitActions.visitRequestFailure("Something went wrong!"));
    } else {
      yield put(visitActions.visitRequestSuccess(parsedDetails));
    }

    // if (typeof details.error === 'undefined') {
    //   yield put(visitActions.visitRequestSuccess(details));
    // } else {
    //   yield put(visitActions.visitRequestFailure(details.error));
    // }
  } catch (error) {
    if (error === 403 || error === 401) {
      yield put(
        authErrorActions.setAuthError(
          true,
          "Session Expired",
          "Please log in again."
        )
      );
    } else {
      yield put(visitActions.visitRequestFailure(error));
      yield put(errorActions.setError(true, "Failure!", error));
    }
  }
}

function* getVisitDetailsLoadMore(action) {
  try {
    //api call

    const details = yield call(Api, {
      endpoint: endpoint.visitDetails,
      method: "POST",
      body: action.request,
      jwt: "Bearer " + action.token,
    });
    const parsedDetails = JSON.parse(details);
    if (parsedDetails[0].Result == "Device is changed") {
      throw 403;
    } else {
      yield put(visitActions.visitRequestLoadMoreSuccess(parsedDetails));
    }

    // if (typeof details.error === 'undefined') {
    //   yield put(visitActions.visitRequestSuccess(details));
    // } else {
    //   yield put(visitActions.visitRequestFailure(details.error));
    // }
  } catch (error) {
    if (error === 403 || error === 401) {
      yield put(
        authErrorActions.setAuthError(
          true,
          "Session Expired",
          "Please log in again."
        )
      );
    } else {
      yield put(visitActions.visitRequestLoadMoreFailure(error));
      yield put(errorActions.setError(true, "Failure!", error));
    }
  }
}
