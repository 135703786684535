import { connectPCPType } from "../types";
const initialState = {
  loading: false,
  sending: false,
  messages: [],
  sentMessage: "",
  errorMsgs: "",
  errorSent: "",
};

export const connectPCPReducer = (state = initialState, action) => {
  switch (action.type) {
    case connectPCPType.CONNECT_PCP_REQUEST:
      return { ...initialState, loading: true };
    case connectPCPType.CONNECT_PCP_REQUEST_SUCCESS:
      return { ...initialState, messages: action.messages };
    case connectPCPType.CONNECT_PCP_REQUEST_FAILURE:
      return { ...initialState, errorMsgs: action.errorMsgs };

    case connectPCPType.CONNECT_PCP_MSG_REQUEST:
      return { ...state, sending: true, sentMessage: "" };
    case connectPCPType.CONNECT_PCP_MSG_REQUEST_SUCCESS:
      return { ...state, sentMessage: action.message };
    case connectPCPType.CONNECT_PCP_MSG_REQUEST_FAILURE:
      return { ...state, errorSent: action.errorSent, sentMessage: "" };
    case connectPCPType.CONNECT_PCP_MSG_REQUEST_RESET:
      return { ...state, errorSent: "", errorMsgs: "", sentMessage: "" };
    default:
      return state;
  }
};
