import { forgetPasswordTypes } from "../types";

const initialState = {
  sending: false,
  // message: "",
  patientId: "",
  error: "",
};
export const forgetPasswordConfirmOtpReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case forgetPasswordTypes.FORGET_PASSWORD_CONFIRM_OTP_REQUEST:
      return {
        ...initialState,
        sending: true,
      };
    case forgetPasswordTypes.FORGET_PASSWORD_CONFIRM_OTP_REQUEST_SUCCESS:
      return {
        ...initialState,
        message: action.message,
        patientId: action.patientId,
      };
    case forgetPasswordTypes.FORGET_PASSWORD_CONFIRM_OTP_REQUEST_FAILURE:
      return {
        ...initialState,
        error: action.error,
      };
    case forgetPasswordTypes.FORGET_PASSWORD_CONFIRM_OTP_REQUEST_RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
