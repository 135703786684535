//import dependencies
import { put, takeLatest, call } from "@redux-saga/core/effects";

//api
import { Api } from "../../../../api";
import { endpoint } from "../../../../api/Url";
import { profileActions } from "../../../profile/Profile/profile.action";
import { loginActions } from "../actions";
import { loginType } from "../types";

export function* watchLogin() {
  yield takeLatest(loginType.LOGIN_REQUEST, userLogin);
}

function* userLogin(action) {
  try {
    //   api call
    const data = yield call(Api, {
      endpoint: endpoint.login,
      method: "POST",
      body: action.request,
    });

    const parsedData = JSON.parse(data);

    if (parsedData.length > 0) {
      if (parsedData[0].Result !== undefined) {
        yield put(loginActions.loginRequestFailure(parsedData[0].Result));
      } else if (parsedData[0].token !== undefined) {
        yield put(loginActions.loginRequestSuccess(parsedData[0]));
        let req = { deviceid: action.request.deviceID };
        yield put(profileActions.profileRequest(req, parsedData[0].token));
      }
    } else {
      yield put(
        loginActions.loginRequestFailure("Something went wrong. Try again...")
      );
    }
    // if (typeof parsedData[0].token != "undefined") {
    //   yield put(loginActions.loginRequestSuccess(parsedData[0]));
    // } else if (typeof parsedData[0].Result != "undefined") {
    //   yield put(loginActions.loginRequestFailure(parsedData[0].Result));
    // } else {
    //   yield put(loginActions.loginRequestFailure("Something went wrong!"));
    // }
  } catch (error) {
    yield put(loginActions.loginRequestFailure(error));
  }
}
