import React, { useEffect, useState } from "react";

import styles from "./Login.module.scss";
import * as BsIcons from "react-icons/bs";
import { BtnPrimary } from "../../../core";
import { Progress } from "../../../components/Progress";
import { Logo } from "../../../components";
import { Error, ValidationError } from "../../../components2";
import { handleEnter, isEmpty } from "../../../util";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loginActions, rememberActions } from "./actions";
import { useForm } from "react-hook-form";

export const Login = () => {
  const dispatch = useDispatch();
  const login = useSelector((state) => state.login);
  const remember = useSelector((state) => state.remember);
  const [isPasswordVisible, setPasswordVisible] = useState(false);

  const [loginId, setLoginId] = useState("");
  const [hasRemember, setRemember] = useState(false);
  const [password, setPassword] = useState("");

  const [errorLoginId, setErrorLoginId] = useState(false);
  const [errorPassword, setErrorPassword] = useState(false);
  const { handleSubmit, formState } = useForm(); // eslint-disable-line
  useEffect(() => {
    if (remember.remember) {
      setRemember(remember.remember);
      setLoginId(remember.loginId);
      setPassword(remember.password);
    }
  }, [remember]);
  const performLogin = () => {
    if (!validate()) {
      if (hasRemember) {
        dispatch(rememberActions.rememberRequest(loginId, password));
      } else {
        dispatch(rememberActions.rememberRequestReset());
      }
      let request = {
        loginID: loginId,
        password: password,
        deviceID: "F5GFGBB08-C9DF-4357-A7D2-435F885D6BF8",
        isDeviceIDUpdated: 0,
      };
      dispatch(loginActions.loginRequest(request));
      // navigate("/home");
    }
  };

  const validate = () => {
    let cancel = false;
    if (isEmpty(loginId)) {
      setErrorLoginId(true);
      cancel = true;
    } else {
      setErrorLoginId(false);
    }
    if (isEmpty(password)) {
      setErrorPassword(true);
      cancel = true;
    } else {
      setErrorPassword(false);
    }
    return cancel;
  };

  return (
    <div className={styles.container}>
      <div className={styles.login_container}>
        <Logo />
        <label className={styles.heading}>Pact Patient</label>
        <form
          onSubmit={handleSubmit(performLogin)}
          className={styles.login_form}
        >
          <div className={styles.input_container}>
            <input
              className={styles.input}
              // type="email"
              placeholder="Login ID"
              onChange={(evt) => setLoginId(evt.target.value)}
              value={loginId}
              required={false}
              onKeyDown={handleEnter}
            />
            <BsIcons.BsFillPersonFill className={styles.icon} />
          </div>
          <ValidationError hasError={errorLoginId} errorMessage={"*Required"} />

          <div className={styles.input_container}>
            <input
              className={styles.input}
              type={isPasswordVisible ? "text" : "password"}
              placeholder="Password"
              onChange={(evt) => setPassword(evt.target.value)}
              value={password}
              // onKeyDown={handleEnter}
              required={false}
            />
            {!isPasswordVisible && (
              <BsIcons.BsFillEyeFill
                className={styles.icon}
                onClick={() => setPasswordVisible(!isPasswordVisible)}
              />
            )}
            {isPasswordVisible && (
              <BsIcons.BsFillEyeSlashFill
                className={styles.icon}
                onClick={() => setPasswordVisible(!isPasswordVisible)}
              />
            )}
          </div>

          <ValidationError
            hasError={errorPassword}
            errorMessage={"*Required"}
          />

          <div className={styles.row}>
            <div className={styles.checkbox_container}>
              <input
                type="checkbox"
                className={styles.checkbox}
                checked={hasRemember}
                onChange={(e) => setRemember(e.target.checked)}
              />
              <label className={styles.label}>Remember Me?</label>
            </div>
            <label className={styles.label}>
              <Link
                label="Log In"
                to="/forgotpassword"
                className={styles.forgot}
              >
                Forget Password?
              </Link>
            </label>
          </div>

          <BtnPrimary label="Login" />
        </form>
        <div className={styles.account}>
          <p>
            Don't have account?
            <Link label="Log In" to="/signup" className={styles.signup}>
              Sign Up
            </Link>
          </p>
        </div>
        <Progress hasProgress={login.loggingIn} message={"Logging in..."} />

        <Error error={login.error} />
      </div>
    </div>
  );
};
