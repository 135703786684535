import { mailDetailsTypes } from "../types/mailDetails.type";

export const mailDetailsActions = {
  mailDetailsRequest,
  mailDetailsRequestSuccess,
  mailDetailsRequestFailure,
};
function mailDetailsRequest(request, token) {
  return { type: mailDetailsTypes.MAIL_DETAILS_REQUEST, request, token };
}
function mailDetailsRequestSuccess(getAll) {
  return { type: mailDetailsTypes.MAIL_DETAILS_REQUEST_SUCCESS, getAll };
}
function mailDetailsRequestFailure(error) {
  return { type: mailDetailsTypes.MAIL_DETAILS_REQUEST_FAILURE, error };
}
