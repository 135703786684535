import { pageType } from "../types/page.type";

const initialState = {
  page: "",
  loading: false,
};
export const pageReducer = (state = initialState, action) => {
  switch (action.type) {
    case pageType.PAGE_TYPE_REQUEST:
      return { ...initialState, loading: true };
    case pageType.PAGE_TYPE_REQUEST_SUCCESS:
      return { ...initialState, page: action.page };
    case pageType.PAGE_TYPE_REQUEST_FAILURE:
      return { ...initialState, error: action.error };
    default:
      return state;
  }
};
