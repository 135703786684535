import React from "react";
import { Avatar } from "../../../../../components2/Avatar";
import styles from "./CMitem.module.scss";
export const CMItem = ({ item, onClick }) => {
  return (
    <div className={styles.row} onClick={onClick}>
      <Avatar fullname={item.Username} />
      <div className={styles.name}>
        <label className={styles.pcp}>{item.Username}</label>
        <label className={styles.facility}>{item.Message}</label>
      </div>

      <label className={styles.date}>{item.CreatedDate}</label>
      {/* <div className={item.DCDate === null && styles.active_user} /> */}
    </div>
  );
};
