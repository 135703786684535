import { pageType } from "../types/page.type";

export const pageActions = {
  pageRequest,
  pageRequestSuccess,
  pageRequestFailure,
};
function pageRequest(page) {
  return { type: pageType.PAGE_TYPE_REQUEST, page };
}
function pageRequestSuccess(page) {
  return { type: pageType.PAGE_TYPE_REQUEST_SUCCESS, page };
}
function pageRequestFailure(error) {
  return { type: pageType.PAGE_TYPE_REQUEST_FAILURE, error };
}
