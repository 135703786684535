import React, { useEffect, useState } from "react";
import styles from "./VisitDetails.module.scss";
import { AppBar } from "../../../components";
import { useNavigate, useLocation } from "react-router-dom";
import { VisitInfo, VisitItem } from "./components";
import visitDetails from "./visitDetails.json";
import { Avatar } from "../../../components2/Avatar";
import { useDispatch, useSelector } from "react-redux";
import { visitActions } from "./visit.action";
import { progressActions } from "../../../store/actions/progress.action";
import { Error } from "../../../components2/Error";

export const VisitDetails = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const visits = useSelector((state) => state.visits);
  const userData = useSelector((state) => state.login.userData);
  const [show, setShow] = useState(false);
  const [selectedItem, setSeleteditem] = useState("");
  console.log("visits", visits);
  useEffect(() => {
    let request = {
      page: 1,
      deviceid: userData.DeviceID,
    };
    dispatch(visitActions.visitRequest(request, userData.token));
  }, []);
  useEffect(() => {
    if (visits.loading) {
      dispatch(progressActions.progressRequest("Loading..."));
    } else {
      dispatch(progressActions.progressRequestReset(""));
    }
  }, [visits.loading]);
  if (visits.details)
    return (
      <>
        <AppBar title={"Visit details"} onBack={() => navigate(-1)} />
        <div className={styles.container}>
          {visits.details.length !== 0 ? (
            visits.details.map((item, index) => (
              <VisitItem
                item={item}
                key={item + index}
                onClick={() => {
                  setShow(!show);
                  setSeleteditem(item);
                }}
              />
            ))
          ) : (
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Error error={"No data are available!"} />
            </div>
          )}
        </div>
        {show && (
          <div className={styles.dialog}>
            {selectedItem !== undefined && (
              <VisitInfo
                show={show}
                setShow={(val) => setShow(val)}
                visitInfo={selectedItem}
              />
            )}
          </div>
        )}

        {/* <div className={styles.container}>
          {visits.details.map((item, index) => (
            <VisitItem
              item={item}
              key={item + index}
              onClick={() => {
                setShow(!show);
                setSeleteditem(item);
              }}
            />
          ))}
        </div>
        {show && (
          <div className={styles.dialog}>
            {selectedItem !== undefined && (
              <VisitInfo
                show={show}
                setShow={(val) => setShow(val)}
                visitInfo={selectedItem}
              />
            )}
          </div>
        )} */}
      </>
    );
};
