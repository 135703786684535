import React from "react";
//REACT ROUTER
import { Link } from "react-router-dom";

//HOOKS
import useClickOutside from "../../../CustomHooks/ClickOutside";
import { useState } from "react";
//login actions
import { loginActions } from "../../../pages/auth/Login/actions";

//ICONS , PICS , STYLES
import styles from "./MyProfile.module.scss";
import { MdKeyboardArrowDown } from "react-icons/md";
import { BsThreeDotsVertical } from "react-icons/bs";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { profileActions } from "../../../pages/profile/Profile/profile.action";

const MyProfile = () => {
  const [isProfileOpen, setisProfileOpen] = useState(false);
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.login.userData);

  let domNode = useClickOutside(() => {
    setisProfileOpen(false);
  });
  return (
    <div
      ref={domNode}
      className={styles.avatar_container}
      onClick={() => {
        setisProfileOpen(!isProfileOpen);
      }}
    >
      {/* AVATAR ICON */}
      {/* <div className={styles.icon_avatar_container}></div> */}

      {/* NAME */}
      <div className={styles.name}>
        {/* <span>
          {typeof userData == "undefined" ? "undefined" : userData.UserName}
        </span> */}
        <BsThreeDotsVertical />
      </div>

      {/* AVATAR/SETTINGS SUBMENU */}
      <div
        className={`${styles.menu} ${isProfileOpen ? styles.menu_active : ""}`}
      >
        <div className={styles.info}>
          <span className={styles.name}>
            {userData.FirstName} {userData.LastName}
          </span>
          {/* <span className={styles.role}>
            Role:{" "}
            {typeof userData == "undefined" ? "undefined" : userData.RoleName}
          </span> */}
        </div>
        <div className={styles.settings}>
          {/* <Link to="/">Settings</Link> */}
          <Link
            to="/"
            onClick={() => {
              dispatch(loginActions.logoutRequest());
              dispatch(profileActions.profileRequestReset());
            }}
          >
            Sign Out
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MyProfile;
