export const supportType = {
  //support all msg
  SUPPORT_REQUEST: "SUPPORT_REQUEST",
  SUPPORT_REQUEST_SUCCESS: "SUPPORT_REQUEST_SUCCESS",
  SUPPORT_REQUEST_FAILURE: "SUPPORT_REQUEST_FAILURE",

  // support msg
  SUPPORT_MSG_REQUEST: "SUPPORT_MSG_REQUEST",
  SUPPORT_MSG_REQUEST_SUCCESS: "SUPPORT_MSG_REQUEST_SUCCESS",
  SUPPORT_MSG_REQUEST_FAILURE: "SUPPORT_MSG_REQUEST_FAILURE",
  SUPPORT_MSG_REQUEST_RESET: "SUPPORT_MSG_REQUEST_FAILURE_RESET",
};
