import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import styles from "./Container.module.scss";
import Navbar from "../Navbar";
import { useSelector } from "react-redux";

const Container = ({ stickyNav }) => {
  //const login = useSelector((state) => state.login);

  // const error = useSelector((state) => state.error);

  return (
    <>
      {/* {!login.loggedIn && <Navigate replace to={"/"} />} */}
      <Navbar />
      <div className={styles.container}>
        {stickyNav}
        {/* <h5 className="p-1 btn btn-danger m-0">Something went wrong!</h5> */}
        {/* <Loading message={"please wait..."} /> */}
        <Outlet />
      </div>
    </>
  );
};

export default Container;
