export const forgetPasswordTypes = {
  //SEND OTP
  FORGET_PASSWORD_OTP_REQUEST: "FORGET_PASSWORD_OTP_REQUEST",
  FORGET_PASSWORD_OTP_REQUEST_RESET: "FORGET_PASSWORD_OTP_REQUEST_RESET",
  FORGET_PASSWORD_OTP_REQUEST_SUCCESS: "FORGET_PASSWORD_OTP_REQUEST_SUCCESS",
  FORGET_PASSWORD_OTP_REQUEST_FAILURE: "FORGET_PASSWORD_OTP_REQUEST_FAILURE",

  //CONFIRM OTP
  FORGET_PASSWORD_CONFIRM_OTP_REQUEST: "FORGET_PASSWORD_CONFIRM_OTP_REQUEST",
  FORGET_PASSWORD_CONFIRM_OTP_REQUEST_RESET:
    "FORGET_PASSWORD_CONFIRM_OTP_REQUEST_RESET",
  FORGET_PASSWORD_CONFIRM_OTP_REQUEST_SUCCESS:
    "FORGET_PASSWORD_CONFIRM_OTP_REQUEST_SUCCESS",
  FORGET_PASSWORD_CONFIRM_OTP_REQUEST_FAILURE:
    "FORGET_PASSWORD_CONFIRM_OTP_REQUEST_FAILURE",

  //RESET PASSWORD
  FORGET_PASSWORD_RESET_REQUEST: "FORGET_PASSWORD_RESET_REQUEST",
  FORGET_PASSWORD_RESET_REQUEST_RESET: "FORGET_PASSWORD_RESET_REQUEST_RESET",
  FORGET_PASSWORD_RESET_REQUEST_SUCCESS:
    "FORGET_PASSWORD_RESET_REQUEST_SUCCESS",
  FORGET_PASSWORD_RESET_REQUEST_FAILURE:
    "FORGET_PASSWORD_RESET_REQUEST_FAILURE",
};
