import React from "react";
import styles from "./ProfileView.module.scss";
import { FiEdit } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { Avatar } from "../../../../../components2/Avatar";
export const ProfileView = ({ name, dob, pcp }) => {
  const navigate = useNavigate();
  return (
    <div className={styles.container}>
      {/* <div className={styles.profile_container}>
        <img src="https://picsum.photos/200" className={styles.profile_pic} />
      </div> */}
      <Avatar fullname={name} />
      <div className={styles.profile_info}>
        <label className={styles.name}>{name}</label>
        <label className={styles.dob}>DOB: {dob}</label>
        <label className={styles.pcp}>PCP: {pcp}</label>
      </div>

      <FiEdit
        className={styles.edit_ico}
        onClick={() => navigate("/edit-profile")}
      />
    </div>
  );
};
