export const strings = {
  BTN_FORGET: "Send OTP",
  HEADING: "Forgot Password?",
  SUBHEADING: "",
  DESC: `No worries, we'll recover your password.`,
  LAB_EMAIL: "Email Address",
  PH_EMAIL: "eg.example@xyz.com",

  BTN_BACK_LOGIN: "Back to login",

  BTN_SUBMIT_OTP: "Confirm OTP",
  LAB_OTP: "OTP",
  PH_OTP: "6 digits otp",

  LAB_NEW_PASSWORD: "New Password",
  LAB_CONFIRM_NEW_PASSWORD: "Confirm New Passoword",
  BTN_CONFIRM_RESET: "Reset Password",

  PH_PASSWORD: "**************",

  ERROR_EMAIL_REQUIRED: "Field required",
  ERROR_EMAIL_INVALID: "Invalid email",

  ERROR_OTP_REQUIRED: "6 digits required",
  ERROR_OTP_INVALID: "Invalid email",

  ERROR_PASSWORD_REQUIRED: "Minimum 6 characters required",
  ERROR_PASSWORD_INVALID: "Password not matched.",

  SUCCESS_PASSWORDRESET: "Password changed successfully.",
};
