export const connectPCPType = {
  //support all msg
  CONNECT_PCP_REQUEST: "CONNECT_PCP_REQUEST",
  CONNECT_PCP_REQUEST_SUCCESS: "CONNECT_PCP_REQUEST_SUCCESS",
  CONNECT_PCP_REQUEST_FAILURE: "CONNECT_PCP_REQUEST_FAILURE",

  // support msg
  CONNECT_PCP_MSG_REQUEST: "CONNECT_PCP_MSG_REQUEST",
  CONNECT_PCP_MSG_REQUEST_SUCCESS: "CONNECT_PCP_MSG_REQUEST_SUCCESS",
  CONNECT_PCP_MSG_REQUEST_FAILURE: "CONNECT_PCP_MSG_REQUEST_FAILURE",
  CONNECT_PCP_MSG_REQUEST_RESET: "CONNECT_PCP_MSG_REQUEST_FAILURE_RESET",
};
