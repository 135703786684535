import React from "react";
import { Avatar } from "../../../../../components2/Avatar";
import styles from "./VisitItem.module.scss";
export const VisitItem = ({ item, onClick }) => {
  return (
    <div className={styles.row} onClick={onClick}>
      <Avatar
        fullname={
          item.LastAssignedProvider === ""
            ? item.PCPName
            : item.LastAssignedProvider
        }
      />
      <div className={styles.name}>
        <label className={styles.pcp}>
          {item.LastAssignedProvider === ""
            ? item.PCPName
            : item.LastAssignedProvider}
        </label>
        <label className={styles.facility}>{item.Facility}</label>
      </div>

      <label className={styles.date}>{item.AdmissionDate}</label>
      <div className={item.DCDate === null && styles.active_user} />
    </div>
  );
};
