import { put, call, takeLatest, delay } from "@redux-saga/core/effects";
import { Api } from "../../../api";
import { endpoint } from "../../../api/Url";
import { medicalActions } from "./medical.action";
import { medicalType } from "./medical.type";
export function* watchMedicalDetails() {
  yield takeLatest(medicalType.MEDICAL_REQUEST, getAllMedicalDetails);
}
function* getAllMedicalDetails(action) {
  try {
    const meds = yield call(Api, {
      endpoint: endpoint.getMedicationsList,
      method: "POST",
      body: action.request,
      jwt: "Bearer " + action.token,
    });
    const parsedMeds = JSON.parse(meds);

    yield put(
      medicalActions.medicalDetailsRequestSuccess(parsedMeds.MedicationsList)
    );
    console.log(parsedMeds);
  } catch (error) {
    console.log(error);
    yield put(medicalActions.medicalDetailsRequestFailure(error));
  }
}
