import { cmConversationsType } from "./cmConversations.type";

export const cmConversationsActions = {
  cmConversationsRequest,
  cmConversationsRequestSuccess,
  cmConversationsRequestFailure,
};

function cmConversationsRequest(request, token) {
  return {
    type: cmConversationsType.CM_CONVERSATIONS_REQUEST,
    request,
    token,
  };
}
function cmConversationsRequestSuccess(conversations) {
  return {
    type: cmConversationsType.CM_CONVERSATIONS_REQUEST_SUCCESS,
    conversations: conversations,
  };
}
function cmConversationsRequestFailure(error) {
  return {
    type: cmConversationsType.CM_CONVERSATIONS_REQUEST_FAILURE,
    error: error,
  };
}
