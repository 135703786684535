import { supportType } from "../types";
const initialState = {
  loading: false,
  sending: false,
  messages: [],
  sentMessage: "",
  errorMsgs: "",
  errorSent: "",
};

export const supportReducer = (state = initialState, action) => {
  switch (action.type) {
    case supportType.SUPPORT_REQUEST:
      return { ...initialState, loading: true };
    case supportType.SUPPORT_REQUEST_SUCCESS:
      return { ...initialState, messages: action.messages };
    case supportType.SUPPORT_REQUEST_FAILURE:
      return { ...initialState, errorMsgs: action.errorMsgs };

    case supportType.SUPPORT_MSG_REQUEST:
      return { ...state, sending: true, sentMessage: "" };
    case supportType.SUPPORT_MSG_REQUEST_SUCCESS:
      return { ...state, sentMessage: action.message };
    case supportType.SUPPORT_MSG_REQUEST_FAILURE:
      return { ...state, errorSent: action.errorSent, sentMessage: "" };
    case supportType.SUPPORT_MSG_REQUEST_RESET:
      return { ...state, errorSent: "", errorMsgs: "", sentMessage: "" };
    default:
      return state;
  }
};
