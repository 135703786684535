import {pcpType} from './pcp.type';

export const pcpActions = {
  pcpRequest,
  pcpRequestSuccess,
  pcpRequestFailure,
  pcpRequestReset,

  getAllPCPRequest,
  getAllPCPRequestSuccess,
  getAllPCPRequestFailure,
};

//to update pcp

function pcpRequest(request, token) {
  return {type: pcpType.PCP_UPDATE_REQUEST, request, token};
}
function pcpRequestSuccess(message) {
  return {type: pcpType.PCP_UPDATE_REQUEST_SUCCESS, message: message};
}
function pcpRequestFailure(error) {
  return {type: pcpType.PCP_UPDATE_REQUEST_FAILURE, error: error};
}
function pcpRequestReset() {
  return {type: pcpType.PCP_UPDATE_REQUEST_RESET};
}

//to get all pcp

function getAllPCPRequest(searchStr, token) {
  return {type: pcpType.GETALL_PCP_REQUEST, searchStr, token};
}
function getAllPCPRequestSuccess(getAll) {
  return {type: pcpType.GETALL_PCP_REQUEST_SUCCESS, getAll};
}
function getAllPCPRequestFailure(error) {
  return {type: pcpType.GETALL_PCP_REQUEST_FAILURE, error};
}
