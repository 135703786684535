import { put, call, takeLatest } from "@redux-saga/core/effects";
import { Api } from "../../../../api";
import { endpoint } from "../../../../api/Url";
import { stateType } from "../types";
import { stateActions } from "../actions";

export function* watchStates() {
  yield takeLatest(stateType.STATE_REQUEST, getStates);
}

function* getStates(action) {
  try {
    //api call
    const states = yield call(Api, {
      endpoint: `${endpoint.getState}?searchstr=${action.searchState}`,
      method: "POST",
      jwt: "Bearer " + action.token,
    });
    const parsedStates = JSON.parse(states);
    yield put(stateActions.stateRequestSuccess(parsedStates));
  } catch (error) {
    console.log(error);
    yield put(stateActions.stateRequestFailure(error));
  }
}
