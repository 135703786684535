import { errorType } from "../types/error.type";
export const errorActions = {
  errorRequest,
  errorRequestReset,
};

function errorRequest(message) {
  return { type: errorType.ERROR_REQUEST, message };
}
function errorRequestReset() {
  return { type: errorType.ERROR_REQUEST_RESET };
}
