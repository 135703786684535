import { notifyPCPType } from "./notifyPCP.type";
const initialState = {
  loading: false,
  message: "",
  error: "",
};

export const notifyPCPReducer = (state = initialState, action) => {
  switch (action.type) {
    case notifyPCPType.PCP_REQUEST:
      return { ...initialState, loading: true };
    case notifyPCPType.PCP_REQUEST_SUCCESS:
      return { ...initialState, message: action.message };
    case notifyPCPType.PCP_REQUEST_FAILURE:
      return { ...initialState, error: action.error };

    case notifyPCPType.PCP_REQUEST_RESET:
      return initialState;
    default:
      return state;
  }
};
