import { contactTypes } from "../types/contact.type";
const initialState = {
  loading: false,
};
export const contactReducer = (state = initialState, action) => {
  switch (action.type) {
    case contactTypes.CONTACT_REQUEST:
      return { loading: true };
    case contactTypes.CONTACT_REQUEST_SUCCESS:
      return { getAll: action.getAll };
    case contactTypes.CONTACT_REQUEST_FAILURE:
      return { error: action.error };

    default:
      return state;
  }
};
