import { loginType } from "../types/login.type";
const initalState = {
  loggingIn: false,
  loggedIn: false,
  userData: {},
  error: "",
};

export const loginReducer = (state = initalState, action) => {
  switch (action.type) {
    case loginType.LOGIN_REQUEST:
      return { ...initalState, loggingIn: true };
    case loginType.LOGIN_REQUEST_SUCCESS:
      return {
        ...initalState,
        loggedIn: true,
        userData: action.userData,
      };
    case loginType.LOGIN_REQUEST_FAILURE:
      return { ...initalState, error: action.error };
    case loginType.LOGOUT_REQUEST:
      return initalState;
    default:
      return state;
  }
};
