import React from "react";
import styles from "./MedicalItem.module.scss";
import { Avatar } from "../../../../../components2";
export const MedicalItem = ({ item }) => {
  return (
    <div className={styles.row}>
      <div className={styles.picture}>
        <Avatar fullname={item.Drugname} />
      </div>
      <div className={styles.patient}>
        <label className={styles.disease}>{item.Drugname}</label>
        <label className={styles.ICD}>Dosage: {item.Dosage}</label>
      </div>
      <div className={styles.patient}>
        <label className={styles.ICD}>Frequency: {item.Frequency}</label>
        <label className={styles.ICD}>Route: {item.Route}</label>
      </div>
    </div>
  );
};
