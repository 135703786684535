import React from "react";
import { Loader } from "../../core";
import styles from "./Progress.module.scss";
const Progress = ({ hasProgress, message }) => {
  return (
    <>
      {hasProgress && (
        <div className={styles.container}>
          <div className={styles.progress_container}>
            <Loader />
            <h5 className={styles.progress_msg}>{message}</h5>
          </div>
        </div>
      )}
    </>
  );
};

export { Progress };
