import { pcpType } from "./pcp.type";
const initialState = {
  updateLoading: false,
  getAllLoading: false,
  message: "",
  error: "",
};
export const pcpReducer = (state = initialState, action) => {
  switch (action.type) {
    case pcpType.PCP_UPDATE_REQUEST:
      return { ...initialState, updateLoading: true };
    case pcpType.PCP_UPDATE_REQUEST_SUCCESS:
      return { ...initialState, message: action.message };
    case pcpType.PCP_UPDATE_REQUEST_FAILURE:
      return { ...initialState, error: action.error };
    case pcpType.PCP_UPDATE_REQUEST_RESET:
      return initialState;

    case pcpType.GETALL_PCP_REQUEST:
      return { ...initialState, getAllLoading: true };
    case pcpType.GETALL_PCP_REQUEST_SUCCESS:
      return { ...initialState, getAll: action.getAll };
    case pcpType.PCP_UPDATE_REQUEST_FAILURE:
      return { ...initialState, error: action.error };

    default:
      return state;
  }
};
