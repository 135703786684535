import React, { useState } from "react";

//CONTEXT
import { useContext } from "react";
import NavContext from "../../Context/NavContext";

//REACT ROUTER
import { NavLink } from "react-router-dom";

import { BsPersonCircle, BsThreeDots } from "react-icons/bs";

import { MdOutlineLogout, MdOutlineHelp } from "react-icons/md";
import {
  AiFillHome,
  AiOutlineCloseCircle,
  AiFillSetting,
} from "react-icons/ai";
import { IoIosArrowDropright } from "react-icons/io";

// import logo from "../../assets/images/logo.png";

//STYLES
import styles from "./Navbar.module.scss";
import { useSelector } from "react-redux";

const NavUrl = ({ url, icon, description }) => {
  const { nav, setNav } = useContext(NavContext);

  const checkWindowSize = () => {
    if (window.innerWidth < 1024) setNav(!nav);
  };

  return (
    <li className={styles.li_navlink}>
      <NavLink
        to={`${url}`}
        className={({ isActive }) => (isActive ? styles.active : undefined)}
        onClick={() => checkWindowSize()}
      >
        {icon}
        <span className={styles.description}>{description}</span>
      </NavLink>
    </li>
  );
};

const Navbar = () => {
  const [isOpen, setOpen] = useState(false);
  const { nav, setNav } = useContext(NavContext);
  const userData = useSelector((state) => state.login.userData);
  return (
    <div
      className={`${styles.navbar_container} ${
        nav ? styles.navbar_mobile_active : undefined
      }`}
    >
      <nav className={nav ? undefined : styles.nav_small}>
        {/* LOGO */}
        <div className={styles.logo}>
          {/* <VscDashboard className={styles.logo_icon} /> */}
          <h4 className={styles.pact}>Pact Patient</h4>
          {/* <img alt="pact" className="img-fluid" src={logo} /> */}

          {/* <FaIcons.FaTimes
            className={styles.mobile_cancel_icon}
            onClick={() => {
              setNav(!nav);
            }}
          /> */}
        </div>

        {/* MENU */}
        <ul
          className={styles.menu_container}
          onMouseLeave={() => {
            if (!isOpen) setNav(false);
          }}
          onMouseEnter={() => {
            if (!isOpen) setNav(true);
          }}
        >
          {/* FIRST CATEGORY */}
          <span className={styles.categories}>
            {nav ? "Pages" : <BsThreeDots />}
          </span>
          {userData !== undefined && userData.RoleID !== 9 && (
            <>
              <NavUrl url="home" icon={<AiFillHome />} description="Home" />

              <NavUrl
                url="profile"
                icon={<BsPersonCircle />}
                description="Profile"
              />
              <NavUrl
                url="support"
                icon={<MdOutlineHelp />}
                description="Support"
              />
              <NavUrl
                url="pcp"
                icon={<AiFillSetting />}
                description="Settings"
              />
            </>
          )}
          {/* {userData !== undefined && userData.RoleID === 9 && (
            <>
              <NavUrl
                url="lookup"
                icon={<VscIcons.VscTable />}
                description="Lookup"
              />
              <NavUrl
                url="users"
                icon={<FiIcons.FiUsers />}
                description="Users"
              />
            </>
          )} */}
        </ul>

        <div
          className={`${styles.btn_logout}`}
          onClick={() => {
            setNav(!nav);
          }}
        >
          <MdOutlineLogout />
        </div>
      </nav>

      <div
        className={nav ? styles.mobile_nav_background_active : undefined}
        onClick={() => {
          setOpen(!nav);
          setNav(!nav);
        }}
      ></div>
    </div>
  );
};

export default Navbar;
