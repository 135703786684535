import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { pageActions, progressActions } from "../../../store/actions";
import { Action, ProfileView } from "./components";
import { profileActions } from "./profile.action";
export const Profile = () => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.login.userData);
  const profile = useSelector((state) => state.profile);

  useEffect(() => {
    dispatch(pageActions.pageRequest("Profile"));
    if (userData.token !== undefined) {
      let req = { deviceid: userData.DeviceID };
      dispatch(profileActions.profileRequest(req, userData.token));
    }
  }, []);
  useEffect(() => {
    if (profile.loading) {
      // dispatch(progressActions.progressRequest("Loading..."));
    } else {
      dispatch(progressActions.progressRequestReset(""));
    }
  }, [profile.loading]);
  let actionList = [
    { name: "PCP", open: "pcp" },
    { name: "Visit Details", open: "visit-details" },
    // { name: "Medical Details", open: "medical-details" },
    { name: "About Disease", open: "about-disease" },
    { name: "Support", open: "support" },
    // { name: "Settings", open: "settings" },
  ];

  return (
    <div>
      <ProfileView
        name={`${userData.FirstName} ${userData.LastName}`}
        dob={userData.DOB}
        pcp={userData.PCP}
      />
      {actionList.map((item) => (
        <Action key={item.name + item.open} name={item.name} open={item.open} />
      ))}
    </div>
  );
};
