import { connectPCPType } from "../types";
export const connectPCPActions = {
  connectPCPRequest,
  connectPCPRequestSuccess,
  connectPCPRequestFailure,

  //send message
  connectPCPMsgRequest,
  connectPCPMsgRequestSuccess,
  connectPCPMsgRequestFailure,
  connectPCPMsgRequestReset,
};
//get all msg
function connectPCPRequest(request, token) {
  return {
    type: connectPCPType.CONNECT_PCP_REQUEST,
    request,
    token,
  };
}
function connectPCPRequestSuccess(messages) {
  return {
    type: connectPCPType.CONNECT_PCP_REQUEST_SUCCESS,
    messages: messages,
  };
}
function connectPCPRequestFailure(error) {
  return {
    type: connectPCPType.CONNECT_PCP_REQUEST_FAILURE,
    errorMsgs: error,
  };
}

//send msg

function connectPCPMsgRequest(request, token) {
  return {
    type: connectPCPType.CONNECT_PCP_MSG_REQUEST,
    request,
    token,
  };
}
function connectPCPMsgRequestSuccess(message) {
  return {
    type: connectPCPType.CONNECT_PCP_MSG_REQUEST_SUCCESS,
    message: message,
  };
}
function connectPCPMsgRequestFailure(error) {
  return {
    type: connectPCPType.CONNECT_PCP_MSG_REQUEST_FAILURE,
    errorSent: error,
  };
}
function connectPCPMsgRequestReset() {
  return {
    type: connectPCPType.CONNECT_PCP_MSG_REQUEST_RESET,
  };
}
