import React from "react";
import { Col } from "react-bootstrap";
import styles from "./DiagnosisItem.module.scss";
export const DiagnosisItem = ({ item }) => {
  return (
    <Col
      className={styles.card_container}
      xs={{ span: 4 }}
      sm={{ span: 4 }}
      md={{ span: 4 }}
      lg={{ span: 4 }}
      xl={{ span: 4 }}
    >
      {/* <div className={styles.about_diagnosis}> */}
      <label className={styles.diagnosis_details}>{item}</label>
      {/* </div> */}
    </Col>
  );
};
