import React, { useEffect, useState } from "react";
import styles from "./Signup.module.scss";
import { BtnPrimary } from "../../../core";
import { Link, useNavigate } from "react-router-dom";
import {
  handleEnter,
  isContactInvalid,
  isEmailInvalid,
  isEmpty,
} from "../../../util";
import { AppBar } from "../../../components";
import { strings } from "./strings";
import { Error, InputField } from "../../../components2";
import { useDispatch, useSelector } from "react-redux";
import { progressActions } from "../../../store/actions";
import { useForm } from "react-hook-form";
import { signupActions } from "./signup.action";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
export const Signup = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const signup = useSelector((state) => state.signup);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [address, setAddress] = useState("");
  const [dob, setDob] = useState("");
  const { handleSubmit, formState } = useForm(); // eslint-disable-line
  const [errorFirstName, setErrorFirstName] = useState("");
  const [errorLastName, setErrorlastName] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [errorMobile, setErrorMobile] = useState("");
  const [errorAddress, setErrorAddress] = useState("");
  const [errorDob, setErrorDob] = useState("");

  const performSignup = () => {
    if (!validate()) {
      // dispatch(progressActions.progressRequest("Signing up..."));
      const phone = [
        "(",
        mobile.slice(0, 3),
        ")",
        mobile.slice(3, 6),
        "-",
        mobile.slice(6),
      ].join("");
      let request = {
        firstname: firstName,
        lastname: lastName,
        dOBDATE: new Date(dob).toLocaleDateString("en-CA"),
        cellphone: phone,
        email: email,
        address: address,
      };

      dispatch(signupActions.signupRequest(request));
    }
  };

  const validate = () => {
    let cancel = false;
    if (isEmpty(firstName)) {
      setErrorFirstName("*Required");
      cancel = true;
    } else {
      setErrorFirstName("");
    }
    if (isEmpty(lastName)) {
      setErrorlastName("*Required");
      cancel = true;
    } else {
      setErrorlastName("");
    }
    if (isEmpty(email)) {
      setErrorEmail("*Required");
      cancel = true;
    } else if (isEmailInvalid(email)) {
      cancel = true;
      setErrorEmail("*Invalid email address.");
    } else {
      setErrorEmail("");
    }
    if (isEmpty(mobile)) {
      setErrorMobile("*Required");
      cancel = true;
    } else if (isContactInvalid(mobile)) {
      setErrorMobile("*10 digits required*");
      cancel = true;
    } else {
      setErrorMobile("");
    }
    if (isEmpty(address)) {
      setErrorAddress("*Required");
      cancel = true;
    } else {
      setErrorAddress("");
    }
    if (isEmpty(dob)) {
      setErrorDob("*Required");
      cancel = true;
    } else {
      setErrorDob("");
    }
    return cancel;
  };

  // signup states
  useEffect(() => {
    if (signup.signingup) {
      dispatch(progressActions.progressRequest("Signing up..."));
    } else {
      dispatch(progressActions.progressRequestReset());
    }
  }, [signup]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
  };
  useEffect(() => {
    setTimeout(() => {
      if (signup.message !== "" || signup.error !== "")
        dispatch(signupActions.signupRequestReset());
    }, 2500);
  }, [signup]);

  return (
    <>
      <AppBar title={"Create an account"} onBack={() => navigate("/")} />
      <div className={styles.container}>
        <div className={styles.signup}>
          <form
            className={styles.signup}
            onSubmit={handleSubmit(performSignup)}
          >
            <InputField
              label={strings.LABEL_FIRSTNAME}
              type="text"
              onChange={(evt) => setFirstName(evt.target.value)}
              value={firstName}
              errorMessage={errorFirstName}
              onKeyDown={handleEnter}
            />
            <InputField
              label={strings.LABEL_LASTNAME}
              type="text"
              onChange={(evt) => setLastName(evt.target.value)}
              value={lastName}
              errorMessage={errorLastName}
              onKeyDown={handleEnter}
            />
            <InputField
              label={strings.LABEL_EMAIL}
              type="text"
              onChange={(evt) => setEmail(evt.target.value)}
              value={email}
              onKeyDown={handleEnter}
              errorMessage={errorEmail}
            />
            <InputField
              label={strings.LABEL_MOBILE}
              type="number"
              onChange={(evt) => {
                setMobile(evt.target.value);
              }}
              value={mobile}
              onKeyDown={handleEnter}
              errorMessage={errorMobile}
            />
            <InputField
              label={strings.LABEL_STREET}
              type="text"
              onKeyDown={handleEnter}
              onChange={(evt) => setAddress(evt.target.value)}
              value={address}
              errorMessage={errorAddress}
            />
            <InputField
              label={strings.LABEL_DOB}
              type="date"
              onKeyDown={handleEnter}
              onChange={(evt) => setDob(evt.target.value)}
              value={dob}
              max="9999-12-31"
              errorMessage={errorDob}
            />

            <BtnPrimary label="Sign Up" />
          </form>
          <div className={styles.account}>
            <p>
              Already have an account?
              <Link label="Log In" to="/" className={styles.login}>
                Log In
              </Link>
            </p>
          </div>
          {/* <Error error={signup.message} /> */}

          <Snackbar
            open={signup.message !== ""}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            onClose={handleClose}
          >
            <Alert
              onClose={handleClose}
              severity={"success"}
              sx={{ width: "100%" }}
            >
              {signup.message}
            </Alert>
          </Snackbar>
          <Snackbar
            open={signup.error !== ""}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            onClose={handleClose}
          >
            <Alert
              onClose={handleClose}
              severity={"error"}
              sx={{ width: "100%" }}
            >
              {signup.error}
            </Alert>
          </Snackbar>
        </div>
      </div>
    </>
  );
};
