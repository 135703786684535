import {notifyPCPType} from './notifyPCP.type';
export const notifyPCPActions = {
  notifyPCPRequest,
  notifyPCPRequestSuccess,
  notifyPCPRequestFailure,
  notifyPCPRequestReset,
};

function notifyPCPRequest(request, token) {
  return {type: notifyPCPType.PCP_REQUEST, request, token};
}
function notifyPCPRequestSuccess(message) {
  return {type: notifyPCPType.PCP_REQUEST_SUCCESS, message: message};
}
function notifyPCPRequestFailure(error) {
  return {type: notifyPCPType.PCP_REQUEST_FAILURE, error: error};
}

function notifyPCPRequestReset() {
  return {type: notifyPCPType.PCP_REQUEST_RESET};
}
