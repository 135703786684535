import React, { useEffect } from "react";
import styles from "./MedicalDetails.module.scss";
import { useNavigate } from "react-router-dom";
import { AppBar } from "../../../components";
import medicalDetails from "./medicalDetails.json";
import { MedicalItem } from "./components";
import { useDispatch, useSelector } from "react-redux";
import { medicalActions } from "./medical.action";
import { progressActions } from "../../../store/actions/progress.action";

export const MedicalDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const medical = useSelector((state) => state.medical);
  const userData = useSelector((state) => state.login.userData);

  useEffect(() => {
    if (userData.token !== undefined) {
      let request = {
        deviceid: userData.DeviceID,
      };

      dispatch(medicalActions.medicalDetailsRequest(request, userData.token));
    }
  }, []);
  useEffect(() => {
    if (medical.loading) {
      dispatch(progressActions.progressRequest("Loading..."));
    } else {
      dispatch(progressActions.progressRequestReset(""));
    }
  }, [medical.loading]);
  return (
    <>
      <AppBar title={"My Meds"} onBack={() => navigate(-1)} />
      <div className={styles.container}>
        {/* <div className={styles.medical_details}> */}
        {medical.details.map((item, index) => (
          <MedicalItem key={index} item={item} />
        ))}
        {/* </div> */}
      </div>
    </>
  );
};
