import { put, call, takeLatest } from "@redux-saga/core/effects";
import { Api } from "../../../api";
import { endpoint } from "../../../api/Url";
import { sentActions } from "../actions";
import { sentType } from "../types";
export function* watchSentMail() {
  yield takeLatest(sentType.SENT_REQUEST, sentMail);
}

function* sentMail(action) {
  try {
    let message = yield call(Api, {
      method: "POST",
      endpoint: endpoint.sendMail,
      jwt: "Bearer " + action.token,
      body: action.request,
    });

    let parsedMessage = JSON.parse(message);

    yield put(sentActions.sentRequestSuccess(parsedMessage[0]));
  } catch (error) {
    yield put(sentActions.sentRequestFailure(error));
  }
}
