import { archiveType } from "../types";

export const archiveActions = {
  archiveRequest,
  archiveRequestSuccess,
  archiveRequestFailure,
};

function archiveRequest(request, token) {
  return { type: archiveType.ARCHIVE_MESSAGE_REQUEST, request, token };
}

function archiveRequestSuccess(message) {
  return { type: archiveType.ARCHIVE_MESSAGE_REQUEST_SUCCESS, message };
}

function archiveRequestFailure(error) {
  return { type: archiveType.ARCHIVE_MESSAGE_REQUEST_FAILURE, error };
}
