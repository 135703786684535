import { rememberType } from "../types/remember.type";
const initalState = {
  remember: false,
  loginId: "",
  password: "",
};
export const rememberReducer = (state = initalState, action) => {
  switch (action.type) {
    case rememberType.REMEMBER_REQUEST:
      return {
        remember: true,
        loginId: action.loginId,
        password: action.password,
      };
    case rememberType.REMEMBER_REQUEST_RESET:
      return initalState;
    default:
      return state;
  }
};
