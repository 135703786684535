import { stateType } from "../types";

const initialState = {
  loading: false,
  states: [],
  error: "",
};
export const stateReducer = (state = initialState, action) => {
  switch (action.type) {
    case stateType.STATE_REQUEST:
      return { ...initialState, loading: true };
    case stateType.STATE_REQUEST_SUCCESS:
      return { ...initialState, states: action.states };
    case stateType.STATE_REQUEST_FAILURE:
      return { ...initialState, error: action.error };
    default:
      return state;
  }
};
