import { profileType } from "./profile.type";

export const profileActions = {
  profileRequest,
  profileRequstSuccess,
  profileRequstFailure,
  profileRequestReset,
};

function profileRequest(request, token) {
  return { type: profileType.PROFILE_REQUEST, request, token };
}
function profileRequstSuccess(data) {
  return { type: profileType.PROFILE_REQUEST_SUCCESS, data: data };
}
function profileRequstFailure(error) {
  return { type: profileType.PROFILE_REQUEST_FAILURE, error: error };
}

function profileRequestReset() {
  return { type: profileType.PROFILE_REQUEST_RESET };
}
