import { put, takeLatest, call, delay } from "@redux-saga/core/effects";
import { useSelector } from "react-redux";
import { Api } from "../../../../api";
import { endpoint } from "../../../../api/Url";
import { diseaseActions } from "../actions";
import { diseaseType } from "../types";
export function* watchDisease() {
  yield takeLatest(diseaseType.DISEASE_REQUEST, getDiseases);
}

function* getDiseases(action) {
  try {
    //api call

    const diseases = yield call(Api, {
      endpoint: endpoint.aboutDisease,
      method: "POST",
      body: action.request,
      jwt: "Bearer " + action.token,
    });

    const parsedData = JSON.parse(diseases);

    console.log("saga", parsedData);
    if (typeof parsedData[0].Result !== "undefined") {
      yield put(diseaseActions.diseaseRequestFailure("Something went wrong!"));
    } else {
      yield put(diseaseActions.diseaseRequestSuccess(parsedData));
    }
  } catch (error) {
    yield put(diseaseActions.diseaseRequestFailure(error));
    console.log(error);
  }
}
