import { updateType } from "./update.type";

export const updateActions = {
  updateProfileRequest,
  updateProfileRequestSuccess,
  updateProfileRequestFailure,
  updateProfileRequestReset,
};

function updateProfileRequest(request, token) {
  return { type: updateType.UPDATE_PROFILE_REQUEST, request, token };
}

function updateProfileRequestSuccess(message) {
  return { type: updateType.UPDATE_PROFILE_REQUEST_SUCCESS, message: message };
}

function updateProfileRequestFailure(error) {
  return { type: updateType.UPDATE_PROFILE_REQUEST_FAILURE, error: error };
}

function updateProfileRequestReset() {
  return { type: updateType.UPDATE_PROFILE_REQUEST_RESET };
}
