import { progressType } from "../types";
export const progressActions = {
  progressRequest,
  progressRequestReset,
};

function progressRequest(message) {
  return { type: progressType.PROGRESS_REQUEST, message };
}
function progressRequestReset() {
  return { type: progressType.PROGRESS_REQUEST_RESET };
}
