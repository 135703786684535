import React from "react";
import styles from "./MsgItem.module.scss";
const MsgItem = ({ item }) => {
  return (
    <div className={styles.msg_item_container}>
      <p className={styles.msg_date}>
        {new Date(item.createdAt).toLocaleDateString("en-US") +
          " " +
          new Date(item.createdAt).toLocaleTimeString("en-US")}
      </p>
      <div
        className={
          item.user.id !== "2" ? styles.msg_sender : styles.msg_receiver
        }
      >
        <p className={styles.msg}>{item.text}</p>
        {/* <p className={styles.msg_time}>{msg.time}</p> */}
      </div>

      {/* {item.msgs.map((msg, ind) => (
        <div
          key={msg + ind}
          className={msg.sender === 1 ? styles.msg_sender : styles.msg_receiver}
        >
          <p className={styles.msg} max>
            {msg.message}{" "}
          </p>
          <p className={styles.msg_time}>{msg.time}</p>
        </div>
      ))} */}
    </div>
  );
};

export { MsgItem };
