import { diseaseType } from "../types";
const initialState = {
  loading: false,
  diseases: [],
  error: "",
};
export const diseaseReducer = (state = initialState, action) => {
  switch (action.type) {
    case diseaseType.DISEASE_REQUEST:
      return { ...initialState, loading: true };
    case diseaseType.DISEASE_REQUEST_SUCCESS:
      return { ...initialState, diseases: action.diseases };
    case diseaseType.DISEASE_REQUEST_FAILURE:
      return { ...initialState, error: action.error };
    default:
      return state;
  }
};
