import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AppBar } from "../../../components";
import styles from "./AboutDisease.module.scss";
import { DiseaseItem } from "./components";
import { diseaseActions } from "./actions";
import { progressActions } from "../../../store/actions/progress.action";
import { Error } from "../../../components2/Error";

export const AboutDisease = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.login.userData);
  const diseases = useSelector((state) => state.diseases);

  useEffect(() => {
    if (diseases.loading) {
      dispatch(progressActions.progressRequest("Loading..."));
    } else {
      dispatch(progressActions.progressRequestReset(""));
    }
  }, [diseases.loading]);

  useEffect(() => {
    if (userData.token !== undefined) {
      let request = {
        page: 1,
        deviceid: userData.DeviceID,
      };
      console.log(request);
      dispatch(diseaseActions.diseaseRequest(request, userData.token));
    }
  }, []);

  return (
    <>
      <AppBar title={"About Disease"} onBack={() => navigate(-1)} />
      <div className={styles.container}>
        {diseases.diseases.length !== 0 ? (
          diseases.diseases.map((item, index) => (
            <DiseaseItem item={item} key={item.VideoID} />
          ))
        ) : (
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Error error={"No Videos are available!"} />
          </div>
        )}
      </div>
    </>
  );
};
