import { put, call, takeLatest } from "@redux-saga/core/effects";
import { Api } from "../../../api";
import { endpoint } from "../../../api/Url";

import { notifyPCPActions } from "./notifyPCP.action";
import { notifyPCPType } from "./notifyPCP.type";

export function* watchNotifyPCP() {
  yield takeLatest(notifyPCPType.PCP_REQUEST, getNotifyPCP);
}

function* getNotifyPCP(action) {
  try {
    //api call

    const message = yield call(Api, {
      endpoint: endpoint.notifyPCP,
      method: "POST",
      body: action.request,
      jwt: "Bearer " + action.token,
    });

    const parsedResult = JSON.parse(message);

    if (parsedResult[0].Result == "Success") {
      yield put(
        notifyPCPActions.notifyPCPRequestSuccess(parsedResult[0].Result)
      );
    } else if (parsedResult[0].Result == "Device is changed") {
      throw 403;
    } else {
      throw parsedResult[0].Result;
      // yield put(
      //   notifyPCPActions.notifyPCPRequestFailure('Failed to notify doctor.'),
      // );
    }
  } catch (error) {
    yield put(
      notifyPCPActions.notifyPCPRequestFailure("Failed to notify the doctor.")
    );
  }
}
