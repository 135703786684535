import React, { useEffect, useState } from "react";
import styles from "./VisitInfo.module.scss";
import { Avatar } from "../../../../../components2/Avatar";
import { DiagnosisItem } from "../DiagnosisItem";
import { Row } from "react-bootstrap";
export const VisitInfo = ({ show, setShow, visitInfo }) => {
  const [diagnosis, setDiagnostic] = useState(null);

  useEffect(() => {
    if (visitInfo.Diagnosis !== undefined && visitInfo.Diagnosis !== null) {
      setDiagnostic(visitInfo.Diagnosis.split(","));
    }
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.column}>
        {show && (
          <>
            <div className={styles.row}>
              <div className={styles.picture}>
                <Avatar
                  fullname={
                    visitInfo.LastAssignedProvider === ""
                      ? visitInfo.PCPName
                      : visitInfo.LastAssignedProvider
                  }
                />
                <label className={styles.visitor_name}>
                  {visitInfo.LastAssignedProvider === ""
                    ? visitInfo.PCPName
                    : visitInfo.LastAssignedProvider}
                </label>
              </div>

              {/* <div className={styles.visitor_name}>
                <p>{visitInfo.name}</p>
              </div> */}
            </div>
            <div className={styles.facility}>
              <span className={styles.title}>Facility:</span>
              <span className={styles.name}>{visitInfo.Facility}</span>
            </div>
            <div className={styles.admission_date}>
              <span className={styles.title}>Admission Date:</span>
              <span className={styles.name}>{visitInfo.AdmissionDate}</span>
            </div>
            <div className={styles.discharge_date}>
              <span className={styles.title}>Discharge Date:</span>
              <span className={styles.name}>{visitInfo.DCDate}</span>
            </div>
            <div className={styles.mrn}>
              <span className={styles.title}>MRN:</span>
              <span className={styles.name}>{visitInfo.MRN}</span>
            </div>
            <div className={styles.pcp_name}>
              <span className={styles.title}>PCP Name:</span>
              <span className={styles.name}>{visitInfo.PCPName}</span>
            </div>
            <div className={styles.last_assigned}>
              <span className={styles.title}>Last Assigned PCP:</span>
              <span className={styles.name}>
                {visitInfo.LastAssignedProvider}
              </span>
            </div>

            <div className={styles.diagnosis}>
              <label className={styles.title}> Diagnosis:</label>
              <Row className={styles.diagnosis_container}>
                {diagnosis !== undefined &&
                  diagnosis !== null &&
                  diagnosis.map((item, index) => (
                    <DiagnosisItem key={index} item={item} />
                  ))}
              </Row>
            </div>
          </>
        )}
        <div className={styles.submit_details}>
          <p className={styles.submit} onClick={() => setShow(!show)}>
            OK
          </p>
        </div>
      </div>
    </div>
  );
};
