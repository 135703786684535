import { call, takeLatest, put } from "@redux-saga/core/effects";
import { profileType } from "./profile.type";
import { profileActions } from "./profile.action";
import { Api } from "../../../api";
import { endpoint } from "../../../api/Url";
import { authErrorActions } from "../../../store/actions/authError.action";
import { errorActions } from "../../../store/actions/error.action";

export function* watchProfile() {
  yield takeLatest(profileType.PROFILE_REQUEST, getProfile);
}
function* getProfile(action) {
  try {
    const data = yield call(Api, {
      endpoint: endpoint.getPatientProfile,
      method: "POST",
      body: action.request,
      jwt: "Bearer " + action.token,
    });

    const parsedData = JSON.parse(data);
    yield put(profileActions.profileRequstSuccess(parsedData));
  } catch (error) {
    if (error === 403 || error === 401) {
      yield put(
        authErrorActions.setAuthError(
          true,
          "Session Expired",
          "Please log in again."
        )
      );
    } else {
      yield put(errorActions.setError(true, "Failure!", error));
      yield put(profileActions.profileRequstFailure(error));
    }
  }
}
