import { updateType } from "./update.type";
const initialState = {
  loading: false,
  message: "",
  error: "",
};
export const updateReducer = (state = initialState, action) => {
  switch (action.type) {
    case updateType.UPDATE_PROFILE_REQUEST:
      return { ...initialState, loading: true };
    case updateType.UPDATE_PROFILE_REQUEST_SUCCESS:
      return { ...initialState, message: action.message };
    case updateType.UPDATE_PROFILE_REQUEST_FAILURE:
      return { ...initialState, error: action.error };
    case updateType.UPDATE_PROFILE_REQUEST_RESET:
      return initialState;

    default:
      return state;
  }
};
