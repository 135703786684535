import { sentType } from "../types";

export const sentActions = {
  sentRequest,
  sentRequestSuccess,
  sentRequestFailure,

  sentRequestReset,
};

function sentRequest(request, token) {
  return { type: sentType.SENT_REQUEST, request, token };
}
function sentRequestSuccess(message) {
  return { type: sentType.SENT_REQUEST_SUCCESS, message };
}
function sentRequestFailure(error) {
  return { type: sentType.SENT_REQUEST_FAILURE, error };
}
function sentRequestReset() {
  return { type: sentType.SENT_REQUEST_RESET };
}
