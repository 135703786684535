import { sentMailboxTypes } from "../types";

const initialState = {
  loading: false,
  getAll: [],
};

export const sentMailboxReducer = (state = initialState, action) => {
  switch (action.type) {
    case sentMailboxTypes.SENT_MAILBOX_REQUEST:
      return { ...initialState, loading: true };
    case sentMailboxTypes.SENT_MAILBOX_REQUEST_SUCCESS:
      return { getAll: action.getAll };
    case sentMailboxTypes.SENT_MAILBOX_REQUEST_FAILURE:
      return { error: action.error };
    default:
      return state;
  }
};
