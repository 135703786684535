import { put, takeLatest, call } from "@redux-saga/core/effects";
import { deleteMailActions } from "../actions/deleteMail.action";
import { deleteMailTypes } from "../types/deleteMail.type";
import { endpoint } from "../../../api/Url";
import { Api } from "../../../api";

export function* watchDeleteMail() {
  yield takeLatest(deleteMailTypes.DELETE_MAIL_REQUEST, getDeleteMail);
}

function* getDeleteMail(action) {
  try {
    const deleteMail = yield call(Api, {
      endpoint: endpoint.deleteMail,
      method: "POST",
      jwt: "Bearer " + action.token,
      body: action.request,
    });
    const parsedDeleteMail = JSON.parse(deleteMail);

    yield put(deleteMailActions.deleteMailRequestSuccess(parsedDeleteMail[0]));
  } catch (error) {
    yield put(deleteMailActions.deleteMailRequestFailure(error));

    // if (error.code === 401 || error.code === 403)
    //   yield put(loginActions.logoutRequest());
  }
}
