import {authErrorType} from '../types/authError.type';

export const authErrorActions = {
  setAuthError,
  showAuthError,
  hideAuthError,
};

function setAuthError(hasError, title, desc) {
  if (hasError) {
    return {type: authErrorType.AUTH_ERROR_REQUEST_SHOW, title, desc};
  } else {
    return {type: authErrorType.AUTH_ERROR_REQUEST_HIDE};
  }
}

function showAuthError(title, desc) {
  return {type: authErrorType.AUTH_ERROR_SHOW, title, desc};
}

function hideAuthError() {
  return {type: authErrorType.AUTH_ERROR_HIDE};
}
