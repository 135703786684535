import { cityType } from "../types";

export const cityActions = {
  cityRequest,
  cityRequestSuccess,
  cityRequestFailure,
};

function cityRequest(searchCity, token) {
  return { type: cityType.CITY_REQUEST, searchCity, token };
}
function cityRequestSuccess(cities) {
  return { type: cityType.CITY_REQUEST_SUCCESS, cities };
}
function cityRequestFailure(error) {
  return { type: cityType.CITY_REQUEST_FAILURE, error };
}
