import React, { useEffect, useState } from "react";
import styles from "./ConnectNurse.module.scss";
import { AppBar } from "../../../components";
import { useNavigate, useLocation } from "react-router-dom";
import { CMItem } from "./components";
import { useDispatch, useSelector } from "react-redux";
import { progressActions } from "../../../store/actions/progress.action";
import { cmConversationsActions } from "./cmConversations.action";
import { visitActions } from "../VisitDetails/visit.action";
import { strings } from "./strings";

export const ConnectNurse = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cmConversations = useSelector((state) => state.cmConversations);
  const userData = useSelector((state) => state.login.userData);
  const [show, setShow] = useState(false);
  const [selectedItem, setSeleteditem] = useState("");

  useEffect(() => {
    if (userData.token !== undefined) {
      let request = {
        deviceid: userData.DeviceID,
      };
      dispatch(
        // visitActions.visitRequest(request, userData.token)
        cmConversationsActions.cmConversationsRequest(request, userData.token)
      );
    }
  }, []);
  useEffect(() => {
    if (cmConversations.loading) {
      dispatch(progressActions.progressRequest("Loading..."));
    } else {
      dispatch(progressActions.progressRequestReset(""));
    }
  }, [cmConversations.loading]);
  return (
    <>
      <AppBar title={"Connect to Nurse"} onBack={() => navigate(-1)} />

      <div className={styles.container}>
        {cmConversations.conversations !== undefined &&
        cmConversations.conversations.length === 0 ? (
          <div className={styles.no_msg}>{strings.NO_CONVO}</div>
        ) : (
          cmConversations.conversations.map((item, index) => (
            <CMItem
              item={item}
              key={item + index}
              onClick={() => {
                // setShow(!show);
                // setSeleteditem(item);
                navigate(`/connect-nurse/${item.ChatMessgeID}`, {
                  state: item,
                });
              }}
            />
          ))
        )}
      </div>

      {/* {show && (
        <div className={styles.dialog}>
          {selectedItem !== undefined && (
            <VisitInfo
              show={show}
              setShow={(val) => setShow(val)}
              visitInfo={selectedItem}
            />
          )}
        </div>
      )} */}
    </>
  );
};
