import { call, put, takeLatest } from "@redux-saga/core/effects";
import { Api } from "../../../api";
import { endpoint } from "../../../api/Url";
import { inboxMailboxActions } from "../actions/inboxMailbox.action";
import { inboxMailboxTypes } from "../types/inboxMailbox.type";

export function* watchInboxMailbox() {
  yield takeLatest(inboxMailboxTypes.INBOX_MAILBOX_REQUEST, getInboxMails);
}

function* getInboxMails(action) {
  try {
    // let inboxMails = yield call(Api, {
    //   method: "POST",
    //   endpoint: endpoint.inboxMailbox,
    //   jwt: "Bearer " + action.token,
    // });
    // let parsedInboxMails = JSON.parse(inboxMails);
    let parsedInboxMails = JSON.parse([]);

    yield put(inboxMailboxActions.inboxRequestSuccess(parsedInboxMails));
  } catch (error) {
    yield put(inboxMailboxActions.inboxRequestFailure(error));
  }
}
