import { progressType } from "../types";
const initalState = {
  hasProgress: false,
  message: "",
};
export const progressReducer = (state = initalState, action) => {
  switch (action.type) {
    case progressType.PROGRESS_REQUEST:
      return { ...initalState, hasProgress: true, message: action.message };
    case progressType.PROGRESS_REQUEST_RESET:
      return { ...initalState };
    default:
      return state;
  }
};
