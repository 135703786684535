import { diseaseType } from "../types";
export const diseaseActions = {
  diseaseRequest,
  diseaseRequestSuccess,
  diseaseRequestFailure,
};
function diseaseRequest(request, token) {
  return {
    type: diseaseType.DISEASE_REQUEST,
    request,
    token,
  };
}
function diseaseRequestSuccess(diseases) {
  return {
    type: diseaseType.DISEASE_REQUEST_SUCCESS,
    diseases: diseases,
  };
}
function diseaseRequestFailure(error) {
  return {
    type: diseaseType.DISEASE_REQUEST_FAILURE,
    error: error,
  };
}
