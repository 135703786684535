import React from "react";
//STYLES
import styles from "./RightNavbar.module.scss";

//HOOKS
import { useContext } from "react";

//CONTEXT
import NavContext from "../../Context/NavContext";

//ICONS , IMAGES
import { MdOutlineMenu } from "react-icons/md";

//Components
import MyProfile from "./Submenus/MyProfile";
import { Heading } from "../Heading";
// import { useLocation } from "react-router-dom";
// import Support from "./Submenus/Support";
// import Notifications from "./Submenus/Notifications";
// import Search from "./Submenus/Search";

const RightNavbar = () => {
  // const [path, setPath] = useState("Dashboard");
  // const location = useLocation();
  const { nav, setNav } = useContext(NavContext);
  // useEffect(() => {
  //   setPath(location.pathname.replace("/", ""));
  // }, [location]);

  return (
    <div className={styles.container}>
      {/* BURGER */}
      <div
        className={styles.burger_container}
        onClick={() => {
          setNav(!nav);
        }}
      >
        <MdOutlineMenu />
      </div>

      <Heading />

      {/* ACTIONS */}
      {/* <div className={styles.actions}> */}
      {/* <Search /> */}
      {/* <Notifications /> */}
      {/* <Support /> */}
      {/* </div> */}

      {/* My Profile */}
      <MyProfile />
    </div>
  );
};

export default RightNavbar;
