import {
  put,
  takeLatest,
  takeEvery,
  call,
  delay,
} from "@redux-saga/core/effects";
import { Api } from "../../../../api";
import { endpoint } from "../../../../api/Url";
import { supportActions } from "../actions";
import { supportType } from "../types";

export function* watchSupport() {
  yield takeLatest(supportType.SUPPORT_REQUEST, getSupport);
  yield takeEvery(supportType.SUPPORT_MSG_REQUEST, sendMsg);
}

function* getSupport(action) {
  try {
    //api call
    // yield delay(1000);

    //api call
    const messages = yield call(Api, {
      endpoint: endpoint.getSupportChat,
      method: "POST",
      body: action.request,
      jwt: "Bearer " + action.token,
    });

    const parsedData = JSON.parse(messages);
    if (parsedData[0].Result == "Device is changed") {
      yield put(supportActions.supportRequestFailure("Something went wrong!"));
    } else {
      yield put(supportActions.supportRequestSuccess(parsedData));
    }
  } catch (error) {
    // if (error === 403 || error === 401) {
    //   yield put(
    //     authErrorActions.setAuthError(
    //       true,
    //       "Session Expired",
    //       "Please log in again."
    //     )
    //   );
    // } else {
    //   //  yield put(errorActions.setError(true, 'Failure!', error));
    yield put(supportActions.supportRequestFailure("Something went wrong!"));
    // }
  }
}

function* sendMsg(action) {
  try {
    //api call

    const message = yield call(Api, {
      endpoint: endpoint.sendMessage,
      method: "POST",
      body: action.request,
      jwt: "Bearer " + action.token,
    });

    const parsedMessage = JSON.parse(message);
    if (parsedMessage[0].Result === "Success") {
      yield put(
        supportActions.supportMsgRequestSuccess(parsedMessage[0].Result)
      );
    } else {
      yield put(supportActions.supportMsgRequestFailure("Failed to sent!"));
    }
  } catch (error) {
    yield put(supportActions.supportMsgRequestFailure("Failed to sent!"));
  }
}
