import { cmConversationsType } from "./cmConversations.type";

const initialState = {
  loading: false,
  conversations: [],
  error: "",
};

export const cmConversationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case cmConversationsType.CM_CONVERSATIONS_REQUEST:
      return { ...initialState, loading: true };
    case cmConversationsType.CM_CONVERSATIONS_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        conversations: action.conversations,
      };
    case cmConversationsType.CM_CONVERSATIONS_REQUEST_FAILURE:
      return { ...state, error: action.error, loading: false };
    default:
      return state;
  }
};
