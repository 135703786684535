import React from "react";
import { Col } from "react-bootstrap";

import styles from "./Widget.module.scss";
export const Widget = (props) => {
  return (
    <Col
      className={styles.card_container}
      xs={{ span: 4 }}
      sm={{ span: 4 }}
      md={{ span: 4 }}
      lg={{ span: 4 }}
      xl={{ span: 4 }}
      onClick={props.onClick}
    >
      {/* <GiDoctorFace className={styles.ico} /> */}
      {props.ico}
      <p className={styles.content}>{props.label}</p>
    </Col>
  );
};
