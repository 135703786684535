//imports: dependencies
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

//reducers
import { errorReducer } from "./reducers/error.reducer";
import { progressReducer } from "./reducers/progress.reducer";
import {
  loginReducer,
  redirectReducer,
  rememberReducer,
} from "../pages/auth/Login/reducers";
import { pageReducer } from "./reducers/page.reducer";
import { visitReducer } from "../pages/profile/VisitDetails/visit.reducer";
import { medicalReducer } from "../pages/profile/MedicalDetails/medical.reducer";
import { signupReducer } from "../pages/auth/SignUp/signup.reducer";
import { pcpReducer } from "../pages/profile/PCP/pcp.reducer";
import { profileReducer } from "../pages/profile/Profile/profile.reducer";
import { notifyPCPReducer } from "../pages/home/Home/notifyPCP.reducer";
import { updateReducer } from "../pages/profile/EditProfile/update.reducer";
import { stateReducer } from "../pages/profile/EditProfile/reducers/state.reducer";
import { cityReducer } from "../pages/profile/EditProfile/reducers/city.reducer";
import { messageReducer } from "../pages/profile/Support/reducers/message.reducer";
import { supportReducer } from "../pages/profile/Support/reducers/support.reducer";
import { diseaseReducer } from "../pages/profile/AboutDisease/reducers/disease.reducer";

import {
  sentReducer,
  replyMailReducer,
  mailDetailsReducer,
  archiveReducer,
  deleteMailReducer,
  inboxMailboxReducer,
  archiveMailboxReducer,
  sentMailboxReducer,
  contactReducer,
  contactRoleReducer,
} from "../pages/MailBox/reducers";
import { connectPCPReducer } from "../pages/profile/ConnectPCP/reducers/connectPCP.reducer";
import { connectCMReducer } from "../pages/profile/ConnectNurse/components/Conversations/reducers/connectCM.reducer";
import { cmConversationsReducer } from "../pages/profile/ConnectNurse/cmConversations.reducer";

import {
  forgetPasswordConfirmOtpReducer,
  forgetPasswordResetReducer,
  forgetPasswordOtpReducer,
} from "../pages/auth/ForgetPassword/reducers";

//persist the loginReducer
const loginPersistConfig = {
  key: "loginPactPatient",
  storage,
  whitelist: ["loggedIn", "loggingIn", "userData"],
};
const profilePersistConfig = {
  key: "profileDataPatient",
  storage,
  whitelist: ["data"],
};
const rememberPersistConfig = {
  key: "rememberMePatient",
  storage,
  whitelist: ["remember", "loginId", "password"],
};

export const rootReducer = combineReducers({
  //for page
  pages: pageReducer,

  error: errorReducer,
  progress: progressReducer,
  login: persistReducer(loginPersistConfig, loginReducer),
  signup: signupReducer,
  remember: persistReducer(rememberPersistConfig, rememberReducer),
  visits: visitReducer,
  medical: medicalReducer,
  pcps: pcpReducer,
  profile: persistReducer(profilePersistConfig, profileReducer),
  notifyPCP: notifyPCPReducer,
  updateProfile: updateReducer,
  states: stateReducer,
  cities: cityReducer,
  support: supportReducer,
  connectPCP: connectPCPReducer,

  diseases: diseaseReducer,

  //mail
  inboxMailbox: inboxMailboxReducer,
  archiveMailbox: archiveMailboxReducer,
  deleteMail: deleteMailReducer,
  replyMail: replyMailReducer,
  mailDetails: mailDetailsReducer,
  archive: archiveReducer,
  sent: sentReducer,
  sentMsgs: sentMailboxReducer,
  contact: contactReducer,
  contactRole: contactRoleReducer,

  cmConversations: cmConversationsReducer,
  connectCM: connectCMReducer,

  //forget password
  forgetPasswordOtp: forgetPasswordOtpReducer,
  forgetPasswordConfirmOtp: forgetPasswordConfirmOtpReducer,
  forgetPasswordReset: forgetPasswordResetReducer,
});
