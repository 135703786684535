import { archiveMailboxTypes } from "../types/archiveMailbox.type";

export const archiveMailboxActions = {
  archiveRequest,
  archiveRequestSuccess,
  archiveRequestFailure,
};

function archiveRequest(token) {
  return { type: archiveMailboxTypes.ARCHIVE_MAILBOX_REQUEST, token };
}
function archiveRequestSuccess(getAll) {
  return { type: archiveMailboxTypes.ARCHIVE_MAILBOX_REQUEST_SUCCESS, getAll };
}
function archiveRequestFailure(error) {
  return { type: archiveMailboxTypes.ARCHIVE_MAILBOX_REQUEST_FAILURE, error };
}
