//import:dependencies
import { fork, all } from "@redux-saga/core/effects";
//watchers
import { watchLogin } from "../pages/auth/Login/sagas";
import { watchPages } from "./sagas/page.saga";
import { watchVisit } from "../pages/profile/VisitDetails/visit.saga";
import { watchMedicalDetails } from "../pages/profile/MedicalDetails/medical.saga";
import { watchSignup } from "../pages/auth/SignUp/signup.saga";
import { watchPCP } from "../pages/profile/PCP/pcp.saga";
import { watchProfile } from "../pages/profile/Profile/profile.saga";
import { watchNotifyPCP } from "../pages/home/Home/notifyPCP.saga";
import { watchUpdateProfile } from "../pages/profile/EditProfile/update.saga";
import { watchStates } from "../pages/profile/EditProfile/sagas/state.saga";
import { watchCities } from "../pages/profile/EditProfile/sagas/city.saga";
import { watchSupport } from "../pages/profile/Support/sagas/support.saga";
import { watchDisease } from "../pages/profile/AboutDisease/sagas/disease.saga";

//inbox
import {
  watchArchiveMail,
  watchSentMail,
  watchDeleteMail,
  watchContact,
  watchContactRole,
  watchInboxMailbox,
  watchArchiveMailbox,
  watchSentMailbox,
  watchReplyMail,
  watchMailDetails,
} from "../pages/MailBox/sagas";
import { watchConnectPCP } from "../pages/profile/ConnectPCP/sagas";
import { watchCmConversations } from "../pages/profile/ConnectNurse/cmConversations.saga";
import { watchConnectCM } from "../pages/profile/ConnectNurse/components/Conversations/sagas/connectCM.saga";

import { watchForgetPassword } from "../pages/auth/ForgetPassword/sagas";
//export sagas: Root Saga

export function* rootSaga() {
  //for pages
  yield all([fork(watchPages)]);
  //yield all([fork(watcher)]);
  yield all([fork(watchLogin)]);
  yield all([fork(watchSignup)]);

  yield all([fork(watchVisit)]);
  yield all([fork(watchMedicalDetails)]);
  yield all([fork(watchPCP)]);
  yield all([fork(watchProfile)]);
  yield all([fork(watchNotifyPCP)]);
  yield all([fork(watchUpdateProfile)]);
  yield all([fork(watchStates)]);
  yield all([fork(watchCities)]);
  yield all([fork(watchSupport)]);
  yield all([fork(watchConnectPCP)]);
  yield all([fork(watchDisease)]);

  //mailbox
  yield all([fork(watchContact)]);
  yield all([fork(watchContactRole)]);
  yield all([fork(watchSentMail)]);
  yield all([fork(watchReplyMail)]);
  yield all([fork(watchArchiveMail)]);
  yield all([fork(watchDeleteMail)]);
  yield all([fork(watchSentMailbox)]);
  yield all([fork(watchInboxMailbox)]);
  yield all([fork(watchArchiveMailbox)]);
  yield all([fork(watchMailDetails)]);

  yield all([fork(watchConnectCM)]);
  yield all([fork(watchCmConversations)]);

  //forget password
  yield all([fork(watchForgetPassword)]);
}
