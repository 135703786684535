export const strings = {
  PH_FIRSTNAME: "Your first name",
  PH_lASTNAME: "Your last name ",
  PH_EMAIL: "Your emaill address",
  PH_MOBILE: "Your mobile no.",
  PH_STREET: "Your street address",
  PH_DOB: "Your date of birth",

  LABEL_FIRSTNAME: "First Name",
  LABEL_LASTNAME: "Last Name",
  LABEL_EMAIL: "Email Address",
  LABEL_MOBILE: "Mobile No.",
  LABEL_STREET: "Street Address",
  LABEL_DOB: "Date of Birth",
};
