import { medicalType } from "./medical.type";

const initalState = {
  loading: false,
  details: [],
  error: "",
};
export const medicalReducer = (state = initalState, action) => {
  switch (action.type) {
    case medicalType.MEDICAL_REQUEST:
      return { ...initalState, loading: true };
    case medicalType.MEDICAL_REQUEST_SUCCESS:
      return { ...initalState, details: action.details };
    case medicalType.MEDICAL_REQUEST_FAILURE:
      return { ...initalState, error: action.error };
    default:
      return state;
  }
};
