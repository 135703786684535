import { signupType } from "./signup.type";
const initialState = {
  signingup: false,
  signedup: false,
  message: "",
  error: "",
};

export const signupReducer = (state = initialState, action) => {
  switch (action.type) {
    case signupType.SIGNUP_REQUEST:
      return { ...initialState, signingup: true };
    case signupType.SIGNUP_REQUEST_SUCCESS:
      return { ...initialState, message: action.message, signedup: true };
    case signupType.SIGNUP_REQUEST_FAILURE:
      return { ...initialState, error: action.error };
    case signupType.SIGNUP_REQUEST_RESET:
      return initialState;
    default:
      return state;
  }
};
