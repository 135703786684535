import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import YouTube from "react-youtube";
import { AppBar } from "../../../../../components";
import styles from "./DiseaseDetails.module.scss";
export const DiseaseDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();

  let videoId = location.state.URL.substring(32);
  const opts = {
    height: "280",
    width: "100%",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      // autoplay: 1,
    },
  };

  const _onReady = (event) => {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  };
  return (
    <>
      <AppBar title={location.state.Title} onBack={() => navigate(-1)} />
      <div className={styles.container}>
        <div className={styles.iframe_container}>
          <YouTube
            videoId={videoId}
            opts={opts}
            loading="lazy"
            onReady={_onReady}
          />
        </div>
        <label className={styles.title}>{location.state.Title}</label>
        <p className={styles.desc}>{location.state.Description}</p>
      </div>
    </>
  );
};
