import { put, takeLatest, call } from "@redux-saga/core/effects";
import { Api } from "../../../api";
import { endpoint } from "../../../api/Url";

import { signupActions } from "./signup.action";
import { signupType } from "./signup.type";

export function* watchSignup() {
  yield takeLatest(signupType.SIGNUP_REQUEST, userSignup);
}

function* userSignup(action) {
  try {
    //api call

    const data = yield call(Api, {
      endpoint: endpoint.signup,
      method: "POST",
      body: action.request,
    });
    const parsedData = JSON.parse(data);
    console.log(parsedData);
    if (parsedData[0].Result !== undefined) {
      yield put(signupActions.signupRequestFailure(parsedData[0].Result));
    } else {
      yield put(
        signupActions.signupRequestSuccess(
          "Login credentials are sent to the registed email."
        )
      );
    }
  } catch (error) {
    yield put(signupActions.signupRequestFailure(error));
    console.log(error);
  }
}
