import {
  put,
  takeLatest,
  takeEvery,
  call,
  delay,
} from "@redux-saga/core/effects";
import { Api } from "../../../api";
import { endpoint } from "../../../api/Url";
import { errorActions } from "../../../store/actions/error.action";
import { authErrorActions } from "../../../store/actions/authError.action";
import { pcpActions } from "./pcp.action";
import { pcpType } from "./pcp.type";
import { profileActions } from "../Profile/profile.action";

export function* watchPCP() {
  yield takeLatest(pcpType.PCP_UPDATE_REQUEST, getUpdatePCP);
  yield takeLatest(pcpType.GETALL_PCP_REQUEST, getAllPCP);
}

function* getUpdatePCP(action) {
  try {
    // api call
    const message = yield call(Api, {
      endpoint: endpoint.savePCP,
      body: action.request,
      method: "POST",
      jwt: "Bearer " + action.token,
    });

    const parsedMessage = JSON.parse(message);

    if (parsedMessage[0].Result === "Success") {
      yield put(pcpActions.pcpRequestSuccess(parsedMessage[0].Result));
      let req = { deviceid: action.request.deviceid };
      yield put(profileActions.profileRequest(req, action.token));
    } else {
      yield put(pcpActions.pcpRequestFailure(parsedMessage[0].Result));
    }
  } catch (error) {
    yield put(pcpActions.pcpRequestFailure(error));
  }
}
function* getAllPCP(action) {
  try {
    //api call
    const pcps = yield call(Api, {
      endpoint: `${endpoint.getPCP}?searchstr=${action.searchStr}`,
      method: "GET",
      jwt: "Bearer " + action.token,
    });
    //console.log(pcps);
    const parsedPCPs = JSON.parse(pcps);

    yield put(pcpActions.getAllPCPRequestSuccess(parsedPCPs));
    // if (typeof getAll.error === 'undefined')

    // else yield put(pcpActions.pcpRequestFailure(getAll.error));
  } catch (error) {
    console.log(error);
    // if (error === 403 || error === 401) {
    //   yield put(
    //     authErrorActions.setAuthError(
    //       true,
    //       "Session Expired",
    //       "Please log in again."
    //     )
    //   );
    // } else {
    yield put(pcpActions.getAllPCPRequestFailure(error));
    // }
  }
}
