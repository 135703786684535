import {medicalType} from './medical.type';
export const medicalActions = {
  medicalDetailsRequest,
  medicalDetailsRequestSuccess,
  medicalDetailsRequestFailure,
};

function medicalDetailsRequest(request, token) {
  return {type: medicalType.MEDICAL_REQUEST, request, token};
}
function medicalDetailsRequestSuccess(details) {
  return {type: medicalType.MEDICAL_REQUEST_SUCCESS, details: details};
}
function medicalDetailsRequestFailure(error) {
  return {type: medicalType.MEDICAL_REQUEST_FAILURE, error: error};
}
