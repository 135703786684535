import { put, call, takeEvery, takeLatest } from "@redux-saga/core/effects";
import { Api } from "../../../../api";
import { endpoint } from "../../../../api/Url";
import { cityActions } from "../actions";
import { cityType } from "../types";
export function* watchCities() {
  yield takeLatest(cityType.CITY_REQUEST, getCities);
}

function* getCities(action) {
  try {
    //api call
    const cities = yield call(Api, {
      endpoint: `${endpoint.getCity}?searchstr=${action.searchCity}`,
      method: "POST",
      jwt: "Bearer " + action.token,
    });

    const parsedCities = JSON.parse(cities);
    yield put(cityActions.cityRequestSuccess(parsedCities));
  } catch (error) {
    console.log(error);
    yield put(cityActions.cityRequestFailure(error));
  }
}
