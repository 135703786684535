import React from "react";
import styles from "./RadioGroup.module.scss";
import { ValidationError } from "../ValidationError";
export const RadioGroup = ({
  label,
  rLabel1,
  rLabel2,
  getValue,
  value,
  disabled,
  errorMessage,
}) => {
  return (
    <div className={styles.row}>
      <label className={styles.input_label}>{label}*</label>
      <div className={styles.input}>
        <label className={styles.rLabel}>{rLabel1}</label>
        <input
          name={label}
          disabled={disabled}
          type={"radio"}
          className={styles.radio}
          checked={value === rLabel1}
          value={rLabel1}
          onChange={(val) => getValue(val.target.value)}
        />
        <label className={styles.rlabel}>{rLabel2}</label>
        <input
          name={label}
          disabled={disabled}
          type={"radio"}
          className={styles.radio}
          checked={value === rLabel2}
          value={rLabel2}
          onChange={(val) => getValue(val.target.value)}
        />
      </div>
      <ValidationError
        hasError={errorMessage !== ""}
        errorMessage={errorMessage}
      />
    </div>
  );
};
