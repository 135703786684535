import { cityType } from "../types";
const initialState = {
  loading: false,
  cities: [],
  error: "",
};
export const cityReducer = (state = initialState, action) => {
  switch (action.type) {
    case cityType.CITY_REQUEST:
      return { ...initialState, loading: true };
    case cityType.CITY_REQUEST_SUCCESS:
      return { ...initialState, cities: action.cities };
    case cityType.CITY_REQUEST_FAILURE:
      return { ...initialState, error: action.error };
    default:
      return state;
  }
};
