import React from "react";
import styles from "./InputField.module.scss";
import { ValidationError } from "../ValidationError";
export const InputField = (props) => {
  return (
    <div className={styles.row}>
      <label className={styles.input_label}>{props.label}*</label>
      <input className={styles.input} {...props} />
      <ValidationError
        hasError={props.errorMessage !== ""}
        errorMessage={props.errorMessage}
      />
    </div>
  );
};
