export const strings = {
  PAGE_TITLE: "Edit Profile",
  //input
  LABEL_FULLNAME: "Full Name",
  LABEL_GENDER: "Gender",
  LABEL_GEN_MALE: "Male",
  LABEL_GEN_FEMALE: "Female",
  LABEL_DOB: "Date of Birth",
  LABEL_STREET: "Street Address",
  LABEL_STATE: "State",
  LABEL_CITY: "City",
  LABEL_ZIP: "Zip",

  BTN_UPDATE: "Update",
  //progress
  PROGRESS_UPDATE: "Updating...",
  //ERROR
  ERROR_REQUIRED: "*Required",
  ERROR_INVALID_ZIP: "*Zip code must have 5 digits.",
};
