import React, { useEffect, useState } from "react";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import styles from "./ForgetPassword.module.scss";
import { Button } from "../../../core";
import { strings } from "./strings";
import { InputField } from "./components";
import { forgetPasswordActions } from "./actions";
import { AppBar } from "../../../components";
import { BtnPrimary } from "../../../components2";
import { progressActions } from "../../../store/actions";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import {
  handleEnter,
  isEmailInvalid,
  isEmpty,
  isOTPInvalid,
  isPasswordInvalid,
} from "../../../util";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
export const ForgetPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const forgetPasswordOtp = useSelector((state) => state.forgetPasswordOtp);
  const forgetPasswordConfirmOtp = useSelector(
    (state) => state.forgetPasswordConfirmOtp
  );
  const forgetPasswordReset = useSelector((state) => state.forgetPasswordReset);
  const { handleSubmit, formState } = useForm(); // eslint-disable-line
  const [isOtpSent, setOtpSent] = useState(false);
  const [hasOtp, setHasOtp] = useState(true);
  const [isOTPSuccess, setOTPSuccess] = useState(false);
  const [alertSuccess, setAlertSuccess] = useState("");
  const [alertFailure, setAlertFailure] = useState("");

  const [otp, setOtp] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [errorOtp, setErrorOtp] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [errorPassword, setErrorPassword] = useState("");
  const [errorConfirmPassword, setErrorConfirmPassword] = useState("");

  //send otp
  const sendOtp = () => {
    if (!validate()) {
      let request = {
        email: email,
        deviceID: "F5GFGBB08-C9DF-4357-A7D2-435F885D6BF8",
      };
      dispatch(forgetPasswordActions.forgetPasswordOtpRequest(request));
    }
  };

  useEffect(() => {
    if (forgetPasswordOtp.message !== undefined) {
      if (forgetPasswordOtp.message === "Success") {
        setHasOtp(false);
        setOtpSent(true);
        setAlertSuccess("OTP sent!");
        setAlertFailure("");
      } else {
        setAlertFailure(forgetPasswordOtp.message);
        setAlertSuccess("");
      }
    }

    if (forgetPasswordOtp.sending) {
      dispatch(progressActions.progressRequest("Sending OTP..."));
    } else {
      dispatch(progressActions.progressRequestReset());
    }
  }, [forgetPasswordOtp]);

  const confirmOtp = () => {
    if (!validate()) {
      let request = {
        otp: otp,
        deviceID: "F5GFGBB08-C9DF-4357-A7D2-435F885D6BF8",
      };
      dispatch(forgetPasswordActions.forgetPasswordConfirmOtpRequest(request));
    }
  };

  useEffect(() => {
    if (forgetPasswordConfirmOtp.message !== undefined) {
      if (forgetPasswordConfirmOtp.message === "Success") {
        setOTPSuccess(true);
        setOtpSent(false);
        setAlertSuccess("Verification completed!");
        setAlertFailure("");
      } else {
        setAlertFailure("OTP is not matched!");
        setAlertSuccess("");
      }
    }
    if (forgetPasswordConfirmOtp.sending) {
      dispatch(progressActions.progressRequest("Verifying OTP..."));
    } else {
      dispatch(progressActions.progressRequestReset());
    }
  }, [forgetPasswordConfirmOtp]);

  const resetPassword = () => {
    if (!validate()) {
      if (forgetPasswordConfirmOtp.patientId !== "") {
        let request = {
          password: password,
          confirmPassword: confirmPassword,
          deviceID: "F5GFGBB08-C9DF-4357-A7D2-435F885D6BF8",
          passwordChangePatientId: forgetPasswordConfirmOtp.patientId,
        };
        dispatch(forgetPasswordActions.forgetPasswordResetRequest(request));
      }
    }
  };
  useEffect(() => {
    if (forgetPasswordReset.message !== undefined) {
      if (forgetPasswordReset.message === "Success") {
        // setHasOtp(true);
        // setOTPSuccess(false);
        setAlertSuccess("Password changed successfully!");
        setAlertFailure("");
      } else {
        setAlertFailure("Something went wrong!");
        setAlertSuccess("");
      }
      // dispatch(forgetPasswordActions.forgetPasswordResetRequestReset());
    }
    if (forgetPasswordReset.sending) {
      dispatch(progressActions.progressRequest("Resetting password..."));
    } else {
      dispatch(progressActions.progressRequestReset());
    }
  }, [forgetPasswordReset]);

  const handleClose = (event, reason) => {
    //  dispatch(updateActions.updateProfileRequestReset());

    setAlertFailure("");
    setAlertSuccess("");
    if (reason === "clickaway") {
      return;
    }
  };

  const validate = () => {
    let cancel = false;
    if (hasOtp) {
      if (isEmpty(email)) {
        cancel = true;
        setErrorEmail(strings.ERROR_EMAIL_REQUIRED);
      } else if (isEmailInvalid(email)) {
        cancel = true;
        setErrorEmail(strings.ERROR_EMAIL_INVALID);
      } else {
        setErrorEmail("");
      }
    }

    if (isOtpSent) {
      if (isEmpty(otp)) {
        cancel = true;
        setErrorOtp(strings.ERROR_OTP_REQUIRED);
      } else if (isOTPInvalid(otp)) {
        cancel = true;
        setErrorOtp(strings.ERROR_OTP_REQUIRED);
      } else {
        setErrorOtp("");
      }
    }

    if (isOTPSuccess) {
      if (isEmpty(password)) {
        cancel = true;
        setErrorPassword(strings.ERROR_PASSWORD_REQUIRED);
      } else if (isPasswordInvalid(password)) {
        cancel = true;
        setErrorPassword(strings.ERROR_PASSWORD_REQUIRED);
      } else {
        setErrorPassword("");
      }
      if (isEmpty(confirmPassword)) {
        cancel = true;
        setErrorConfirmPassword(strings.ERROR_PASSWORD_REQUIRED);
      } else if (isPasswordInvalid(confirmPassword)) {
        cancel = true;
        setErrorConfirmPassword(strings.ERROR_PASSWORD_REQUIRED);
      } else if (password !== confirmPassword) {
        cancel = true;
        setErrorConfirmPassword(strings.ERROR_PASSWORD_INVALID);
      } else {
        setErrorConfirmPassword("");
      }
    }
    return cancel;
  };

  const backToLogin = () => {
    navigate("/");
    dispatch(forgetPasswordActions.forgetPasswordResetRequestReset());
    dispatch(forgetPasswordActions.forgetPasswordConfirmOtpRequestReset());
    dispatch(forgetPasswordActions.forgetPasswordOtpRequestReset());
  };

  return (
    <>
      <AppBar title={strings.HEADING} onBack={backToLogin} />
      <div className={styles.container}>
        <div className={styles.main_container}>
          <div className={styles.component_container}>
            {/* <label className={styles.heading}>{strings.SUBHEADING}</label> */}
            <label className={styles.heading}>
              {forgetPasswordReset.message !== "Success"
                ? strings.DESC
                : strings.SUCCESS_PASSWORDRESET}
            </label>
            {hasOtp && (
              <form className={styles.form} onSubmit={handleSubmit(sendOtp)}>
                <InputField
                  label={strings.LAB_EMAIL}
                  placeholder={strings.PH_EMAIL}
                  type="email"
                  error={errorEmail}
                  onChange={(e) => setEmail(e.target.value)}
                />

                <BtnPrimary label={strings.BTN_FORGET} />
              </form>
            )}
            {isOtpSent && (
              <form className={styles.form} onSubmit={handleSubmit(confirmOtp)}>
                <InputField
                  label={strings.LAB_OTP}
                  placeholder={strings.PH_OTP}
                  type="number"
                  onKeyDown={(evt) =>
                    ["e", "E", "+", "-"].includes(evt.key) &&
                    evt.preventDefault()
                  }
                  value={otp}
                  error={errorOtp}
                  onChange={(e) => {
                    if (e.target.value.length <= 6) setOtp(e.target.value);
                  }}
                />

                <BtnPrimary label={strings.BTN_SUBMIT_OTP} />
              </form>
            )}

            {isOTPSuccess && forgetPasswordReset.message !== "Success" && (
              <form
                className={styles.form}
                onSubmit={handleSubmit(resetPassword)}
              >
                <InputField
                  label={strings.LAB_NEW_PASSWORD}
                  placeholder={strings.PH_PASSWORD}
                  type="password"
                  error={errorPassword}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  onKeyDown={handleEnter}
                />
                <InputField
                  label={strings.LAB_CONFIRM_NEW_PASSWORD}
                  placeholder={strings.PH_PASSWORD}
                  type="password"
                  value={confirmPassword}
                  error={errorConfirmPassword}
                  // onKeyDown={handleEnter}
                  onChange={(e) => {
                    setConfirmPassword(e.target.value);
                  }}
                />

                <BtnPrimary label={strings.BTN_CONFIRM_RESET} />
              </form>
            )}
            {forgetPasswordReset.message === "Success" && (
              <>
                <BtnPrimary
                  label={strings.BTN_BACK_LOGIN}
                  onClick={backToLogin}
                />
              </>
            )}
            <Snackbar
              open={alertSuccess !== ""}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              onClose={handleClose}
            >
              <Alert
                onClose={handleClose}
                severity={"success"}
                sx={{ width: "100%" }}
              >
                {alertSuccess}
              </Alert>
            </Snackbar>
            <Snackbar
              open={alertFailure !== ""}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              onClose={handleClose}
            >
              <Alert
                onClose={handleClose}
                severity={"error"}
                sx={{ width: "100%" }}
              >
                {alertFailure}
              </Alert>
            </Snackbar>
          </div>
        </div>
      </div>
    </>
  );
};
