import { signupType } from "./signup.type";

export const signupActions = {
  signupRequest,
  signupRequestSuccess,
  signupRequestFailure,
  signupRequestReset,
};
function signupRequest(request) {
  return {
    type: signupType.SIGNUP_REQUEST,
    request,
  };
}
function signupRequestSuccess(message) {
  return {
    type: signupType.SIGNUP_REQUEST_SUCCESS,
    message: message,
  };
}
function signupRequestFailure(error) {
  return {
    type: signupType.SIGNUP_REQUEST_FAILURE,
    error: error,
  };
}

function signupRequestReset() {
  return {
    type: signupType.SIGNUP_REQUEST_RESET,
  };
}
