import { sentMailboxTypes } from "../types/sentMailbox.type";

export const sentMailboxActions = {
  sentMailboxRequest,
  sentMailboxRequestSuccess,
  sentMailboxRequestFailure,
};

function sentMailboxRequest(token) {
  return { type: sentMailboxTypes.SENT_MAILBOX_REQUEST, token };
}
function sentMailboxRequestSuccess(getAll) {
  return { type: sentMailboxTypes.SENT_MAILBOX_REQUEST_SUCCESS, getAll };
}
function sentMailboxRequestFailure(error) {
  return { type: sentMailboxTypes.SENT_MAILBOX_REQUEST_FAILURE, error };
}
