import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AppBar } from "../../../components";
import { getEachWordFirstChar } from "../../../util";
import { supportActions } from "./actions/support.action";
import { MsgItem } from "./components";
import styles from "./support.module.scss";

import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { progressActions } from "../../../store/actions/progress.action";
import { strings } from "./strings";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const Support = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.login.userData);
  const support = useSelector((state) => state.support);
  const [messages, setMessages] = useState([]);
  const [newMessage, setMessage] = useState("");
  const [scroll, setScroll] = useState("auto");
  const messagesEndRef = useRef(null);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    if (support.loading) {
      dispatch(progressActions.progressRequest("Loading..."));
    } else {
      dispatch(progressActions.progressRequestReset(""));
    }
    if (
      support.messages !== undefined &&
      support.messages.length > 0 &&
      support.messages[0].Result === undefined
    ) {
      setMessages([...support.messages]);
    } else {
      setMessages([]);
    }
  }, [support.messages, support.loading]);

  useEffect(() => {
    // setMessages([...msgsList]);
    if (userData.token !== undefined) {
      let request = {
        deviceid: userData.DeviceID,
        createdDate: new Date().toLocaleDateString("en-CA"),
      };
      dispatch(supportActions.supportRequest(request, userData.token));
    }
  }, []);
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: scroll });
  };

  const sendMessage = () => {
    // msgs = [
    //   ...msgs,
    //   {
    //     message: newMessage,
    //     time: "01:00 AM",
    //     sender: 1,
    //   },
    // ];
    // let message = [
    //   {
    //     date: "05/19/2022",
    //     msgs: [...msgs],
    //   },
    // ];
    // let mList = messages.filter((val) => val.date !== "05/19/2022");

    if (userData.token !== undefined) {
      let request = {
        chatMessage: newMessage,
        deviceid: userData.DeviceID,
      };

      dispatch(supportActions.supportMsgRequest(request, userData.token));
    }
  };

  useEffect(() => {
    if (support.sentMessage === "Success") {
      let newMsg = [
        {
          id: "" + new Date().getTime(),
          text: newMessage,
          createdAt: new Date(),
          user: {
            id: "1",
            senderName:
              userData.FirstName +
              " " +
              userData.MiddleName +
              " " +
              userData.LastName,
          },
        },
      ];

      setMessages([...messages, ...newMsg]);
      setScroll("smooth");
      setMessage("");

      dispatch(supportActions.supportMsgRequestReset());
    }
  }, [support.sentMessage]);

  const handleClose = (event, reason) => {
    dispatch(supportActions.supportMsgRequestReset());
    if (reason === "clickaway") {
      return;
    }
  };
  return (
    <>
      <AppBar title={"Support"} onBack={() => navigate(-1)} />
      <div className={styles.container}>
        <div className={styles.messages_container}>
          <div className={styles.messages}>
            {messages !== undefined && messages.length === 0 ? (
              <div className={styles.no_msg}>{strings.NO_CONVO}</div>
            ) : (
              messages.map((item, index) => (
                <MsgItem key={item.id} item={item} />
              ))
            )}
            <div ref={messagesEndRef} />
          </div>
        </div>
        {/* <div className={styles.send_container}> */}
        <div className={styles.send_container}>
          <textarea
            value={newMessage}
            className={styles.msg_input}
            type={"text"}
            placeholder="Your message..."
            onChange={(e) => setMessage(e.target.value)}
            rows={1}
            cols={2}
          />
          <button
            className={styles.send_btn}
            onClick={sendMessage}
            disabled={newMessage.trim() === ""}
          >
            Send
          </button>
        </div>
        {/* </div> */}
        <Snackbar
          open={support.errorSent !== ""}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
            {support.errorSent}
          </Alert>
        </Snackbar>
        <Snackbar
          open={support.errorMsgs !== ""}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
            {support.errorMsgs}
          </Alert>
        </Snackbar>
      </div>
    </>
  );
};
