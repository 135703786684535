import { put, takeLatest, call } from "@redux-saga/core/effects";
import { Api } from "../../../api";
import { endpoint } from "../../../api/Url";
import { sentMailboxTypes } from "../types";
import { sentMailboxActions } from "../actions";

export function* watchSentMailbox() {
  yield takeLatest(sentMailboxTypes.SENT_MAILBOX_REQUEST, getSentMails);
}

function* getSentMails(action) {
  try {
    // let sentMails = yield call(Api, {
    //   method: "POST",
    //   endpoint: endpoint.sentMailbox,
    //   jwt: "Bearer " + action.token,
    // });

    // let parsedMails = JSON.parse(sentMails);
    let parsedMails = [];

    yield put(sentMailboxActions.sentMailboxRequestSuccess(parsedMails));
  } catch (error) {
    yield put(sentMailboxActions.sentMailboxRequestFailure(error));
  }
}
