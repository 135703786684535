import React from "react";
import styles from "./Action.module.scss";
import { MdNavigateNext } from "react-icons/md";
import { useNavigate } from "react-router-dom";
export const Action = ({ name, open }) => {
  const navigate = useNavigate();
  return (
    <div className={styles.container} onClick={() => navigate(`/${open}`)}>
      <label className={styles.label}>{name}</label>
      <MdNavigateNext className={styles.next_ico} />
    </div>
  );
};
