//import dependencies
import createSagaMiddleware from "@redux-saga/core";
import { createStore, applyMiddleware } from "redux";
import { persistStore } from "redux-persist";

//import root reducer
import { rootReducer } from "./reducers";
//import:redux root saga
import { rootSaga } from "./sagas";

const sagaMiddleWare = createSagaMiddleware();
const store = createStore(rootReducer, applyMiddleware(sagaMiddleWare));
sagaMiddleWare.run(rootSaga);
export { store };
export const persistor = persistStore(store);
