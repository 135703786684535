import {
  put,
  delay,
  takeLatest,
  takeEvery,
  call,
} from "@redux-saga/core/effects";
import { Api } from "../../../api";
import { endpoint } from "../../../api/Url";
// import { profileActions } from "../profile/profile.action";

import { updateActions } from "./update.action";
import { updateType } from "./update.type";

export function* watchUpdateProfile() {
  yield takeLatest(updateType.UPDATE_PROFILE_REQUEST, getUpdateProfile);
}
function* getUpdateProfile(action) {
  try {
    // const message = 'Success!';
    const message = yield call(Api, {
      endpoint: endpoint.UpdatePatientProfile,
      method: "POST",
      body: action.request,
      jwt: "Bearer " + action.token,
    });
    const parsedMessage = JSON.parse(message);

    if (parsedMessage[0].Result === "Success") {
      let req = { deviceid: action.request.deviceid };
      // yield put(profileActions.profileRequest(req, action.token));
      yield put(updateActions.updateProfileRequestSuccess(message));
    } else if (parsedMessage[0].Result == "Device is changed") {
      throw 403;
    } else {
      //  console.log(message);
      throw "Something went wrong";
    }
  } catch (error) {
    // if (error === 403 || error === 401) {
    //   yield put(
    //     authErrorActions.setAuthError(
    //       true,
    //       "Session Expired",
    //       "Please log in again."
    //     )
    //   );
    // }
    //else {
    yield put(updateActions.updateProfileRequestFailure(error));
    //  }
  }
}
