import { replyMailTypes } from "../types/replyMail.type";

export const replyMailActions = {
  replyMailRequest,
  replyMailRequestReset,
  replyMailRequestSuccess,
  replyMailRequestFailure,
};
function replyMailRequest(request, token) {
  return { type: replyMailTypes.REPLY_MAIL_REQUEST, request, token };
}
function replyMailRequestReset() {
  return { type: replyMailTypes.REPLY_MAIL_REQUEST_RESET };
}
function replyMailRequestSuccess(message) {
  return { type: replyMailTypes.REPLY_MAIL_REQUEST_SUCCESS, message };
}
function replyMailRequestFailure(error) {
  return { type: replyMailTypes.REPLY_MAIL_REQUEST_FAILURE, error };
}
