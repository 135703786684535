import React from "react";
import styles from "./InputField.module.scss";
export const InputField = (props) => {
  return (
    <div className={styles.input_container}>
      <label className={styles.label}>{props.label}:</label>
      <input className={styles.email_input} {...props} />
      <label className={styles.error}>{props.error}</label>
    </div>
  );
};
