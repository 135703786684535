import React from "react";
import { Spinner } from "..";

import styles from "./BtnPrimary.module.scss";

export const BtnPrimary = (props) => {
  return (
    <button className={styles.btn_container} onClick={props.onClick}>
      {props.label}
    </button>
  );
};
