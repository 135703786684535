/**
 * @url
 * base url
 */
export const Url = {
  baseURL: `https://patient.hitspact.com/api/PatientPortalApi`,
};

/**
 *
 * @endpoint
 *to get enpoints
 */
export const endpoint = {
  signup: "/signup",
  login: "/login",
  getPatientProfile: "/GetPatientProfileDetails",
  visitDetails: "/VisitDetails",
  getPCP: "/pcps",
  savePCP: "/save-pcp-preference",
  getCity: "/GetCity",
  getState: "/GetState",
  aboutDisease: "/VideoList",
  notifyPCP: "/notify-pcp",
  UpdatePatientProfile: "/UpdatePatientProfile",

  getMedicationsList: "/getMedicationsList",
  //support
  getSupportChat: "/GetChatHistoryForPatientPortal",
  sendMessage: "/SaveChatMessageByPatientPortal",
  getConnectCMChat: "/getNursechatDetails",
  sendCMMessage: "/savePatientNurse_ChatDetails",
  getPCPChat: "/getDoctorchat",
  sendPCPMessage: "/SavePatientDoctor_ChatDetails",

  //connect nurse
  cmConversations: "/getNursechatHistory",

  //forgot password
  createForgotPasswordOtp: "/createForgotPasswordOtp",
  verifyForgotPasswordOtp: "/verifyForgotPasswordOtp",
  changePasswordForPatientApp: "/changePasswordForPatientApp",
};
