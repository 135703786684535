import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { pageActions, progressActions } from "../../../store/actions";
import styles from "./Home2.module.scss";
import { useNavigate } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { Col, Row } from "react-bootstrap";
import { notifyPCPActions } from "../Home/notifyPCP.action";
import { Progress } from "../../../components2";
import { Widget } from "../Home/components";
import medsImg from "../../../assets/images/my_meds.png";
import notifyDoctorImage from "../../../assets/images/notifyYourDoctor.png";
import visitImg from "../../../assets/images/visit_details.png";
import connectDoctorImg from "../../../assets/images/televisits.png";
import connectNurseImg from "../../../assets/images/talktonurse.png";
import diseaseImg from "../../../assets/images/about_disease.png";
import { FcBusinessman, FcBusinesswoman } from "react-icons/fc";
import { Typography } from "@mui/material";
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const Home2 = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const profileData = useSelector((state) => state.profile.data);
  const userData = useSelector((state) => state.login.userData);
  const notifyPCP = useSelector((state) => state.notifyPCP);

  const [profileDetails, setProfieDetails] = useState(null);

  const [lat, setLat] = useState(null);
  const [lng, setLng] = useState(null);
  const [locationstatus, setLocationStatus] = useState(null);

  //default values
  useEffect(() => {
    dispatch(pageActions.pageRequest("Home"));
    dispatch(progressActions.progressRequestReset(""));
  }, []);

  useEffect(() => {
    if (
      profileData !== undefined &&
      profileData !== null &&
      profileData.PersonalDetails !== undefined &&
      profileData.PersonalDetails.length > 0
    ) {
      setProfieDetails(profileData.PersonalDetails[0]);
    }
  }, [profileData]);

  /**To get patient lat long */
  const getLocation = () => {
    const location_timeout = setTimeout(10000);
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        function (position) {
          clearTimeout(location_timeout);
          notifyYourPCP(position.coords.latitude, position.coords.longitude);
          setLat(position.coords.latitude);
          setLng(position.coords.longitude);
          setLocationStatus(null);
        },
        function (error) {
          clearTimeout(location_timeout);
          console.error("Error Code = " + error.code + " - " + error.message);
          alert("Please grant location accesss for this site.");

          setLocationStatus(error.message);
        }
      );
    } else {
      alert("Geolocation is not supported by your browser.");
      setLocationStatus("Geolocation is not supported by your browser.");
    }
  };

  const notifyYourPCP = async (lat, lng) => {
    if (userData.token !== undefined) {
      let request = {
        deviceid: userData.DeviceID,
        latitude: `${lat}`,
        longitude: `${lng}`,
        locationDetails: "",
        pcpid: profileData.PCPInfo[0].PCPID,
        pcpname: profileData.PCPInfo[0].PCPName,
      };

      dispatch(notifyPCPActions.notifyPCPRequest(request, userData.token));
    }
  };

  const handleClose = (event, reason) => {
    dispatch(notifyPCPActions.notifyPCPRequestReset());
    if (reason === "clickaway") {
      return;
    }
  };

  const Widget2 = (props) => {
    <Col
      className={styles.card_container}
      xs={{ span: 4 }}
      sm={{ span: 4 }}
      md={{ span: 4 }}
      lg={{ span: 4 }}
      xl={{ span: 4 }}
      onClick={props.onClick}
    >
      {/* <GiDoctorFace className={styles.ico} /> */}
      {props.ico}
      <p className={styles.content}>{props.label}</p>
    </Col>;
  };

  return (
    <div className={styles.container}>
      <Progress
        hasProgress={notifyPCP.loading}
        message="Notifying the doctor..."
      />

      <Snackbar
        open={notifyPCP.error !== ""}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {notifyPCP.error}
        </Alert>
      </Snackbar>
      <Snackbar
        open={notifyPCP.message !== ""}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          {"Successfully notified!"}
        </Alert>
      </Snackbar>

      <div className={styles.row}>
        {/* <div className={styles.item}>
        </div> */}
        <div className={styles.item} onClick={getLocation}>
          <img src={notifyDoctorImage} className={styles.ico} />
        </div>

        <div
          className={styles.item}
          onClick={() =>
            navigate("/connect-nurse", { state: "Connect to Nurse" })
          }
        >
          <img src={connectNurseImg} className={styles.ico} />
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.item} onClick={() => navigate("/visit-details")}>
          <img src={visitImg} className={styles.ico} />
        </div>
        <div className={styles.item} onClick={() => navigate("/profile")}>
          {profileDetails !== null && profileDetails.Gender === "Male" ? (
            <FcBusinessman style={{ width: "100%", height: "100%" }} />
          ) : (
            <FcBusinesswoman style={{ width: "100%", height: "100%" }} />
          )}
          {profileDetails !== null && (
            <p>
              {profileDetails.FirstName} {profileDetails.LastName}
            </p>
          )}
        </div>
        <div className={styles.item}>
          <img
            src={connectDoctorImg}
            className={styles.ico}
            onClick={() =>
              navigate("/connect-doctor", { state: "Connect to Doctor" })
            }
          />
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.item} onClick={() => navigate("/about-disease")}>
          <img src={diseaseImg} className={styles.ico} />
        </div>
        <div className={styles.item} onClick={() => navigate("/my-meds")}>
          <img src={medsImg} className={styles.ico} />
        </div>
      </div>
    </div>
  );
};
