import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AppBar } from "../../../components";
import {
  Avatar,
  BtnPrimary,
  RadioGroup,
  InputField,
  ValidationError,
} from "../../../components2";
import { default as ReactSelect } from "react-select";
import styles from "./EditProfile.module.scss";
import { isEmpty, isZipCodeInvalid } from "../../../util";
import { useDispatch, useSelector } from "react-redux";
import { progressActions } from "../../../store/actions";
import { strings } from "./strings";
import { updateActions } from "./update.action";
import { profileActions } from "../Profile/profile.action";
import { stateActions } from "./actions/state.action";
import { cityActions } from "./actions/city.action";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
export const EditProfile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userData = useSelector((state) => state.login.userData);
  const profileData = useSelector((state) => state.profile.data);
  const updateProfile = useSelector((state) => state.updateProfile);

  const states = useSelector((state) => state.states);
  const cities = useSelector((state) => state.cities);

  //input
  const [fullName, setFullName] = useState("");
  const [gender, setGender] = useState("");
  const [dob, setDOB] = useState("");
  const [street, setStreet] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [zip, setZip] = useState("");

  //error
  const [errorFullName, setErrorFullName] = useState("");
  const [errorGender, setErrorGender] = useState("");
  const [errorDOB, setErrorDOB] = useState("");
  const [errorStreet, setErrorStreet] = useState("");
  const [errorState, setErrorState] = useState("");
  const [errorCity, setErrorCity] = useState("");
  const [errorZip, setErrorZip] = useState("");

  const performUpdate = () => {
    if (!validate()) {
      // setTimeout(() => {
      //   dispatch(progressActions.progressRequestReset());
      // }, 1000);
      if (userData.token !== undefined) {
        let gen = gender === strings.LABEL_GEN_MALE ? 1 : 2;
        let request = {
          address: street,
          zip: zip,
          state: state.StateName,
          city: city.CityName,
          genderId: gen,
          deviceid: userData.DeviceID,
        };

        dispatch(updateActions.updateProfileRequest(request, userData.token));
      }
    }
  };

  useEffect(() => {
    if (userData !== undefined && profileData.PersonalDetails.length > 0) {
      let personalInfo = profileData.PersonalDetails[0];
      let contactInfo = profileData.ContactDetails[0];
      setFullName(
        personalInfo.FirstName +
          " " +
          personalInfo.MiddleName +
          " " +
          personalInfo.LastName
      );
      setGender(personalInfo.Gender);
      setCity({ CityName: contactInfo.City, CityName: contactInfo.City });
      setZip(contactInfo.Zip);
      setStreet(contactInfo.Address);
      setState({
        StateName: contactInfo.StateFullName,
        StateName: contactInfo.StateFullName,
      });
      setDOB(new Date(personalInfo.DOB).toLocaleDateString("en-CA"));
    }
  }, [profileData]);
  const validate = () => {
    let cancel = false;
    // if (isEmpty(fullName)) {
    //   setErrorFullName(strings.ERROR_REQUIRED);
    //   cancel = true;
    // } else {
    //   setErrorFullName("");
    // }
    if (isEmpty(gender)) {
      setErrorGender(strings.ERROR_REQUIRED);
      cancel = true;
    } else {
      setErrorGender("");
    }

    // if (isEmpty(dob)) {
    //   cancel = true;
    //   setErrorDOB(strings.ERROR_REQUIRED);
    // } else {
    //   setErrorDOB("");
    // }

    if (isEmpty(street)) {
      setErrorStreet(strings.ERROR_REQUIRED);
      cancel = true;
    } else {
      setErrorStreet("");
    }
    if (isEmpty(state)) {
      cancel = true;
      setErrorState(strings.ERROR_REQUIRED);
    } else {
      setErrorState("");
    }

    if (isEmpty(city)) {
      cancel = true;
      setErrorCity(strings.ERROR_REQUIRED);
    } else {
      setErrorCity("");
    }

    if (isEmpty(zip)) {
      cancel = true;
      setErrorZip(strings.ERROR_REQUIRED);
    } else if (isZipCodeInvalid(zip)) {
      cancel = true;
      setErrorZip(strings.ERROR_INVALID_ZIP);
    } else {
      setErrorZip("");
    }
    return cancel;
  };
  useEffect(() => {
    if (updateProfile.loading) {
      dispatch(progressActions.progressRequest("Updating..."));
    } else {
      dispatch(progressActions.progressRequestReset());
    }
    if (updateProfile.message === "Success" && userData.token !== undefined) {
      let req = { deviceid: userData.DeviceID };
      dispatch(profileActions.profileRequest(req, userData.token));
    }
  }, [updateProfile]);
  const handleClose = (event, reason) => {
    dispatch(updateActions.updateProfileRequestReset());

    if (reason === "clickaway") {
      return;
    }
  };

  const getState = (val) => {
    if (val.length > 2) {
      if (userData.token !== undefined) {
        let request = {
          searchStr: val,
        };

        dispatch(stateActions.stateRequest(request, userData.token));
      }
    }
  };
  const getCity = (val) => {
    if (val.length > 2) {
      if (userData.token !== undefined) {
        let request = {
          searchStr: val,
        };

        dispatch(cityActions.cityRequest(request, userData.token));
      }
    }
  };

  return (
    <>
      <AppBar title={strings.PAGE_TITLE} onBack={() => navigate(-1)} />
      <div className={styles.container}>
        <InputField
          label={strings.LABEL_FULLNAME}
          onChange={(e) => setFullName(e.target.value)}
          errorMessage={errorFullName}
          value={fullName}
          disabled
        />
        <RadioGroup
          label={strings.LABEL_GENDER}
          rLabel1={strings.LABEL_GEN_MALE}
          rLabel2={strings.LABEL_GEN_FEMALE}
          value={gender}
          getValue={(val) => setGender(val)}
          errorMessage={errorGender}
        />

        <InputField
          label={strings.LABEL_DOB}
          type="date"
          onChange={(e) => setDOB(e.target.value)}
          errorMessage={errorDOB}
          value={dob}
          max="9999-12-31"
          disabled
        />

        <InputField
          label={strings.LABEL_STREET}
          onChange={(e) => setStreet(e.target.value)}
          errorMessage={errorStreet}
          value={street}
        />
        <div className={styles.row}>
          <label className={styles.input_label}>{strings.LABEL_STATE}*</label>
          <ReactSelect
            className={styles.input}
            onInputChange={getState}
            getOptionLabel={(option) => option.StateName}
            getOptionValue={(option) => option.StateName}
            options={states.states}
            isLoading={states.loading}
            value={state}
            onChange={(e) => setState(e)}
          />

          <ValidationError hasError={errorState} errorMessage={"*Required"} />
        </div>

        {/* <InputField
          label={strings.LABEL_STATE}
          onChange={(e) => setState(e.target.value)}
          errorMessage={errorState}
          value={state}
        /> */}
        <div className={styles.row}>
          <label className={styles.input_label}>{strings.LABEL_CITY}*</label>
          <ReactSelect
            className={styles.input}
            onInputChange={getCity}
            getOptionLabel={(option) => option.CityName}
            getOptionValue={(option) => option.CityName}
            options={cities.cities}
            isLoading={cities.loading}
            value={city}
            onChange={(e) => setCity(e)}
          />

          <ValidationError hasError={errorCity} errorMessage={"*Required"} />
        </div>
        {/* <InputField
          label={strings.LABEL_CITY}
          onChange={(e) => setCity(e.target.value)}
          errorMessage={errorCity}
          value={city}
        /> */}
        <InputField
          label={strings.LABEL_ZIP}
          type="number"
          onChange={(e) => setZip(e.target.value)}
          errorMessage={errorZip}
          value={zip}
        />
        <BtnPrimary label={strings.BTN_UPDATE} onClick={performUpdate} />
        <Snackbar
          open={updateProfile.message !== ""}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity={"success"}
            sx={{ width: "100%" }}
          >
            {"Successfully updated!"}
          </Alert>
        </Snackbar>
        <Snackbar
          open={updateProfile.error !== ""}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity={"error"}
            sx={{ width: "100%" }}
          >
            {"Failed to update!"}
          </Alert>
        </Snackbar>
      </div>
    </>
  );
};
