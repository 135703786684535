export const isEmpty = (val) => val === "";
export const isEmptyOrNull = (val) => val === "" || val === null;
export const isContactEmptyOrInvalid = (val) => val === "" || val.length !== 10;
export const isContactInvalid = (val) => val.length !== 10;
export const isZipCodeInvalid = (val) => val.length !== 5;
export const isOTPInvalid = (val) => val.length !== 6;

export const isPasswordInvalid = (val) => val.length < 6;

export const isEmailInvalid = (text) => {
  let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/; // eslint-disable-line
  if (reg.test(text) === false) {
    return true;
  } else {
    return false;
  }
};

// export const isDigitInvalid = (val) => isNaN(val)
export const isPriceEmptyOrInvalid = (val) =>
  val === "" || val === 0 || val === "0";

export const dateDiff = (sdate, edate) => {
  const date1 = new Date(sdate);
  const date2 = new Date(edate);

  const diffTime = date2 - date1;
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays;
};

// to get first chars of first and last name
export const getEachWordFirstChar = (fullName) => {
  const mataches = fullName.match(/\b(\w)/g);
  const acronym = mataches.join("");
  return acronym.slice(0, 2);
};

export const handleEnter = (event) => {
  if (event.keyCode === 13) {
    const form = event.target.form;
    const index = Array.prototype.indexOf.call(form, event.target);
    form.elements[index + 1].focus();
    event.preventDefault();
  }
};
