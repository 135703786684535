import React, { useEffect } from "react";

import "./App.css";
import { useState } from "react";

import { Routes, Route, Navigate, useNavigate } from "react-router-dom";

// import Container from "./components/Container/Container";
// import RightNavbar from "./components/RightNavbar/RightNavbar";
import NavContext from "./Context/NavContext";

//auth
import { Login, Signup, ForgotPassword, ForgetPassword } from "./pages/auth";
import { MainContainer } from "./components";

import { Home, Home2 } from "./pages/home";

// import { Support } from "./pages/support";
import {
  EditProfile,
  PCP,
  // MedicalDetails,
  Profile,
  Settings,
  VisitDetails,
  Support,
  AboutDisease,
  DiseaseDetails,
  MyMeds,
  ConnectDoctor,
  ConnectNurse,
  MedicalDetails,
  ConnectPCP,
  Conversations,
} from "./pages/profile";

import Container from "./components/Container/Container";
import RightNavbar from "./components/RightNavbar/RightNavbar";
import { Progress } from "./components2";
import { useSelector } from "react-redux";
import MailBox from "./pages/MailBox";

// import { useSelector } from "react-redux";

// import {
//   //getTokenFrom,
//   onMessageListener,
// } from "./firebase";
//import { loginActions } from "./pages/Login/actions/login.action";

function App() {
  const [nav, setNav] = useState(false);
  const value = { nav, setNav };
  const navigate = useNavigate();
  // const [isTokenFound, setTokenFound] = useState(false);
  // const [show, setShow] = useState(false);
  // const [notification, setNotification] = useState({ title: "", body: "" });
  //getTokenFrom(setTokenFound);

  // onMessageListener()
  //   .then((payload) => {
  //     // setShow(true);
  //     if (window.Notification.permission === "granted") {
  //       navigator.serviceWorker.getRegistration().then(function (reg) {
  //         const notificationTitle = payload.notification.title;
  //         const notificationOptions = {
  //           body: payload.notification.body,
  //         };

  //         reg.showNotification(notificationTitle, notificationOptions);
  //         // console.log("Message received. ", payload);
  //       });
  //     }
  //   })
  //   .catch((err) => console.log("failed: ", err));
  const login = useSelector((state) => state.login);
  useEffect(() => {
    if (login.loggedIn) {
      navigate("/home");
    } else {
      navigate("/");
    }
  }, [login.loggedIn]);
  const progress = useSelector((state) => state.progress);
  return (
    <div className="App">
      <Progress hasProgress={progress.hasProgress} message={progress.message} />
      <NavContext.Provider value={value}>
        <Routes>
          {/* <Route path="/" element={<Login />} /> */}
          <Route element={<MainContainer />}>
            <Route path="/" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/forgotpassword" element={<ForgetPassword />} />
            <Route path="/visit-details" element={<VisitDetails />} />
            <Route path="/pcp" element={<PCP />} />
            <Route path="/edit-profile" element={<EditProfile />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/support" element={<Support />} />
            {/* <Route path="/medical-details" element={<MedicalDetails />} /> */}
            <Route path="/about-disease" element={<AboutDisease />} />
            <Route path="/my-meds" element={<MedicalDetails />} />
            <Route path="/connect-doctor" element={<ConnectPCP />} />
            <Route path="/connect-nurse" element={<ConnectNurse />} />
            <Route
              path="/connect-nurse/:conversationId"
              element={<Conversations />}
            />
            <Route
              path="/about-disease/:videoId"
              element={<DiseaseDetails />}
            />
          </Route>
          <Route element={<Container stickyNav={<RightNavbar />} />}>
            <Route path="/home" element={<Home2 />} />
            <Route path="/support" element={<Support />} />
            <Route path="/profile" element={<Profile />} />
          </Route>
        </Routes>
      </NavContext.Provider>
    </div>
  );
}

export default App;
