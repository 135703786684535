import React from "react";
import { Outlet } from "react-router-dom";
import styles from "./MainContainer.module.scss";
export const MainContainer = () => {
  return (
    <div className={styles.maincontainer}>
      <Outlet />
    </div>
  );
};
