export const connectCMType = {
  //connectCm all msg
  CONNECT_CM_REQUEST: "CONNECT_CM_REQUEST",
  CONNECT_CM_REQUEST_SUCCESS: "CONNECT_CM_REQUEST_SUCCESS",
  CONNECT_CM_REQUEST_FAILURE: "CONNECT_CM_REQUEST_FAILURE",

  // CONNECT msg
  CONNECT_CM_MSG_REQUEST: "CONNECT_CM_MSG_REQUEST",
  CONNECT_CM_MSG_REQUEST_SUCCESS: "CONNECT_CM_MSG_REQUEST_SUCCESS",
  CONNECT_CM_MSG_REQUEST_FAILURE: "CONNECT_CM_MSG_REQUEST_FAILURE",
  CONNECT_CM_MSG_REQUEST_RESET: "CONNECT_CM_MSG_REQUEST_FAILURE_RESET",
};
