import { put, takeLatest, call } from "@redux-saga/core/effects";
import { Api } from "../../../api";
import { endpoint } from "../../../api/Url";
import { mailDetailsActions } from "../actions";
import { mailDetailsTypes } from "../types/mailDetails.type";
export function* watchMailDetails() {
  yield takeLatest(mailDetailsTypes.MAIL_DETAILS_REQUEST, mailDetails);
}

function* mailDetails(action) {
  try {
    let message = yield call(Api, {
      method: "POST",
      endpoint: endpoint.mailDetails,
      jwt: "Bearer " + action.token,
      body: action.request,
    });

    let parsedMessage = JSON.parse(message);

    yield put(mailDetailsActions.mailDetailsRequestSuccess(parsedMessage));
  } catch (error) {
    yield put(mailDetailsActions.mailDetailsRequestFailure(error));
  }
}
