import React from "react";
import { useSelector } from "react-redux";
import styles from "./Heading.module.scss";
// import * as AiIcons from "react-icons/ai";
// import { useHistory } from "react-router-dom";

const Heading = () => {
  // const history = useHistory();
  const page = useSelector((state) => state.pages.page);
  return (
    <div className={styles.title}>
      <div className={styles.title_icon}>
        {/* <AiIcons.AiFillHome onClick={() => history.push("/dashboard")} /> */}
        <h1>{page}</h1>
      </div>
    </div>
  );
};

export { Heading };
