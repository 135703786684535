import { call, put, takeLatest } from "@redux-saga/core/effects";
import { Api } from "../../../api";
import { endpoint } from "../../../api/Url";
import { archiveMailboxActions } from "../actions/archiveMailbox.action";
import { archiveMailboxTypes } from "../types/archiveMailbox.type";

export function* watchArchiveMailbox() {
  yield takeLatest(
    archiveMailboxTypes.ARCHIVE_MAILBOX_REQUEST,
    getArchiveMails
  );
}

function* getArchiveMails(action) {
  try {
    // let archiveMails = yield call(Api, {
    //   method: "POST",
    //   endpoint: endpoint.archiveMailbox,
    //   jwt: "Bearer " + action.token,
    // });
    // let parsedArchiveMails = JSON.parse(archiveMails);
    let parsedArchiveMails = JSON.parse([]);

    yield put(archiveMailboxActions.archiveRequestSuccess(parsedArchiveMails));
  } catch (error) {
    yield put(archiveMailboxActions.archiveRequestFailure(error));
  }
}
