import React from "react";
import { getEachWordFirstChar } from "../../util";
import styles from "./Avatar.module.scss";
const Avatar = ({ fullname }) => {
  return (
    <div className={styles.col1}>
      <div className={styles.avatar_circle}>
        <label className={styles.avatar_char}>
          {getEachWordFirstChar(fullname)}
        </label>
      </div>
    </div>
  );
};

export { Avatar };
