import React from "react";
import { Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import styles from "./DiseaseItem.module.scss";

export const DiseaseItem = ({ item }) => {
  const navigate = useNavigate();
  let videoId = item.URL.substring(32);
  let thumbnailUrl = `http://img.youtube.com/vi/${videoId}/0.jpg`;
  console.log(thumbnailUrl);

  const playVideo = () => {
    ///about-disease/video-id
    navigate(`/about-disease/${videoId}`, { state: item });
  };
  return (
    <div className={styles.row} onClick={playVideo}>
      <div className={styles.thumbnail_container}>
        {/* <Avatar fullname={item.disease} /> */}
        <img src={thumbnailUrl} className={styles.thumbnail} />
        {/* <YouTube videoId={videoId} className={styles.thumbnail} opts={opts} /> */}
      </div>
      <div className={styles.patient}>
        <label className={styles.disease}>{item.Title}</label>
        {/* <label className={styles.desc}>{item.desc}</label> */}
      </div>
    </div>
  );
};
